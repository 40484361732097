import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FinsteinTooltipModule } from 'src/app/core/directives/finstein-tooltip/finstein-tooltip.module';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { InputModule } from '../../../core/components/input/input.module';
import { EllipsifyModule } from '../../../core/directives/ellipsify/ellipsify.module';
import { ApprovePendingChangesModule } from '../approve-pending-changes/approve-pending-changes.module';
import { HistoricalDataModule } from '../historical-data/historical-data.module';
import { ListCompanyCanteensComponent } from './list-company-canteens.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [
        ListCompanyCanteensComponent
    ],
    imports: [
        TranslateModule,
        CommonModule,
        NgSelectModule,
        NgOptionHighlightModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        TranslateModule,
        ReactiveFormsModule,
        InputModule,
        EllipsifyModule,
        ApprovePendingChangesModule,
        FinsteinTooltipModule,
        HistoricalDataModule
    ],
    exports: [
        ListCompanyCanteensComponent
    ]
})
export class ListCompanyCanteensModule {
}
