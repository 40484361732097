import { BaseModalModule } from '../../../core/components/base-modal/base-modal.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CreateOptinModalComponent} from './create-optin-modal.component';
import {TranslateModule} from '@ngx-translate/core';
import {TableModule} from '../../../core/components/table/table.module';
import {QuickSearchModule} from '../../../core/components/quick-search/quick-search.module';
import {InputErrorModule} from '../../../core/components/input-error/input-error.module';
import {PageTitleModule} from '../../../core/components/page-title/page-title.module';
import {ReactiveFormsModule} from '@angular/forms';
import {InputModule} from '../../../core/components/input/input.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';


@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        TableModule,
        QuickSearchModule,
        MatProgressSpinnerModule,
        InputErrorModule,
        MatDialogModule,
        NgxMaskDirective,
        NgxMaskPipe,
        PageTitleModule,
        ReactiveFormsModule,
        InputModule,
        BaseModalModule,
        NgSelectModule,
        NgOptionHighlightModule
    ],
    declarations: [CreateOptinModalComponent],
    exports: [CreateOptinModalComponent],
    providers: [provideNgxMask()]
})
export class CreateOptinModule {
}
