import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {PaginationComponent} from './pagination.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
    ],
    declarations: [PaginationComponent],
    exports: [PaginationComponent]
})
export class PaginationModule {
}
