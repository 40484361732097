import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {CommonModule} from '@angular/common';
import {InputErrorModule} from '../input-error/input-error.module';
import {ReactiveFormsModule} from '@angular/forms';
import {SelectComponent} from './select.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgOptionHighlightModule} from '@ng-select/ng-option-highlight';
import { MatTooltipModule } from '@angular/material/tooltip';
import {HistoricalDataModule} from '../../../shared/components/historical-data/historical-data.module';
import {
    ApprovePendingChangesModule
} from '../../../shared/components/approve-pending-changes/approve-pending-changes.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        InputErrorModule,
        ReactiveFormsModule,
        NgSelectModule,
        NgOptionHighlightModule,
        MatTooltipModule,
        HistoricalDataModule,
        ApprovePendingChangesModule
    ],
    declarations: [SelectComponent],
    exports: [SelectComponent]
})
export class SelectModule {
}
