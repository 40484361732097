import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {UserService} from '../../services/user.service';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss']
})
export class SelectComponent implements OnInit {

    // Required
    @Input() items!: any[]; // Required if booleanOptions is false
    @Input() form!: any;
    @Input() controlName!: string;
    @Input() label!: string;
    @Input() bindLabel!: string;
    @Input() bindValue?: string;
    @Input() customLabel: (item: any) => string;
    @Input() searchFn: (term: string, item: any) => boolean;
    @Input() showProperty!: string;
    @Input() optionTooltip: boolean;
    @Input() optionTooltipPos: 'left' | 'right' | 'above' | 'below' | 'before' | 'after' = 'left';

    // Optionals
    /** This attribute displays the gray mandatory attribute text below the input */
    @Input() hasPendentApproval = false;
    @Input() showRequiredValidator = false;
    @Input() booleanOptions = false;
    @Input() placeholder: string;
    @Input() multiple = true;
    @Input() closeOnSelect = true;
    @Input() disabled = false;
    @Input() clearable = true;
    @Input() searchable = true;
    @Input() appendTo;
    /** This attribute leaves a space below the entry, which will be the space for the error to appear */
    @Input() showEmpty = true;

    // Optional params to approve and see historical data
    @Input() item: any;
    @Input() permissionToApprove: string;
    @Input() customApprovalProperty: string;
    @Input() customApprovalShowValue: any; // Function to personalize the presentation of the oldValue and newValue

    constructor(private translate: TranslateService,
                private userService: UserService) {
    }

    ngOnInit(): void {
        if (this.booleanOptions) {
            this.multiple = false;
            this.items = [true, false];
        }
    }

    public setItem(item: any) {
        if (this.bindLabel) {
            if (this.multiple) {
                this.form.get(this.controlName).setValue(item.map((value) => value[this.bindLabel]));
            } else {
                this.form.get(this.controlName).setValue(item);
            }
        }
    }

    public getLabel(item: any) {
        if (this.customLabel) {
            return this.customLabel(item);
        } else {
            let label = item;

            if (this.booleanOptions) {
                label = item ? 'system.yes' : 'system.no';
            } else if (item && this.showProperty && item[this.showProperty]) {
                label = item[this.showProperty];
            }
            return this.translate.instant(label);
        }
    }

    getRequiredMarker() {
        const validator = this.form?.get(this.controlName)?.validator;
        return validator && validator({} as AbstractControl)?.required ? '*' : '';
    }

    hasPendentApprovalForThisProperty(item: any, property: string) {
        if (!this.userService.loggedUser.finsteinUser && item && item.pendentApproval && item.pendentApproval.data) {
            return item.pendentApproval.data.find(data => data.property === property);
        }
    }

    hasHistoricalDataForThisProperty(item: any, property: string) {
        return item && item.historicalData &&
            item.historicalData.data &&
            item.historicalData.data.find(data => data.property === property);
    }

    getInputComplementClass() {
        return this.label ? 'select-input-complement-with-label' : 'select-input-complement-without-label';
    }
}
