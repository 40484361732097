<finstein-base-modal [title]="'optins.record-manual-optin-dialog-title' | translate" actionPosition="end">
    <finstein-custom-circle-icon color="principal-color" header>
        <i class="material-icons" icon>person_add</i>
    </finstein-custom-circle-icon>
    <div content>
        <form [formGroup]="editForm">
            <div class="row">
                <div class="col-4">
                    <div class="d-flex align-items-center">
                        <span class="required-marker mr-1 mb-1">*</span>
                        <label class="w-100" for="gender">{{ 'optins.gender' | translate }}</label>
                    </div>
                    <ng-select id="gender"
                               class="e-select multiple-select"
                               appendTo="body"
                               [placeholder]="'common.please-select' | translate"
                               [items]="genders"
                               [multiple]="false"
                               bindLabel="label"
                               bindValue="value"
                               formControlName="gender">
                        <ng-template ng-label-tmp let-item="item">
                            {{item.label | translate}}
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                <span [ngOptionHighlight]="search">
                                    {{item.label | translate}}
                                </span>
                        </ng-template>
                    </ng-select>
                    <app-input-error field="gender" [formGroup]="editForm"></app-input-error>
                </div>
                <app-input class="col-4"
                    [form]="editForm"
                    controlName="firstName"
                    placeholder="common.placeholders.first-name"
                    label="users.firstName">
                </app-input>
                <app-input class="col-4"
                    [form]="editForm"
                    controlName="lastName"
                    placeholder="common.placeholders.last-name"
                    label="users.lastName">
                </app-input>
            </div>
            <div class="row">
                <app-input class="col-4"
                    [form]="editForm"
                    type="email"
                    controlName="email"
                    placeholder="common.placeholders.email"
                    label="common.email">
                </app-input>
                <app-input class="col-4"
                    type="date"
                    [dateMin]="minAge"
                    [dateMax]="maxAge"
                    label="common.birthday"
                    controlName="birthday"
                    [form]="editForm">
                </app-input>
                <app-input class="col-4" type="tel"
                    [form]="editForm"
                    controlName="phone"
                    label="common.phone"
                    [placeholder]="inputMask.phonePlaceholder">
                </app-input>
            </div>
        </form>
    </div>
    <finstein-action-form-buttons actions [showCancelBtn]="false" saveBtnTitle="optins.record-manual-optin-dialog-save" (save)="submit()"></finstein-action-form-buttons>
</finstein-base-modal>
