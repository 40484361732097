import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {BaseEditComponent} from "../../../core/abstractions/base-edit.component";
import {UntypedFormControl, Validators} from "@angular/forms";
import {CustomValidators} from "../../../core/util/custom-validators";
import {USER_CHANGE_PASSWORD} from "../../../app.constants";

@Component({
    selector: 'app-cookie-dialog',
    templateUrl: './change-password-dialog.component.html',
    styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent extends BaseEditComponent implements OnInit {

    showPassword = false;
    showConfirmPassword = false;

    constructor(private dialogRef: MatDialogRef<ChangePasswordDialogComponent>) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    getFormControls() {
        return {
            password: new UntypedFormControl(undefined, [Validators.required, CustomValidators.noWhitespaceValidator, CustomValidators.isPasswordValid]),
            confirmPassword: new UntypedFormControl(undefined, [Validators.required, CustomValidators.noWhitespaceValidator, CustomValidators.checkPasswordsValidator]),
        };

    }

    submit() {
        if (this.editForm.valid) {
            this.spinner.show();
            const password = this.editForm?.value?.password;
            const email = this.userService.loggedUser.email;
            this.service.callFunction({password, email}, USER_CHANGE_PASSWORD, { type: 'callable', httpMethod: 'POST' })
                .subscribe({
                    next: (res) => {
                        this.spinner.hide();
                        this.dialogRef.close(password);
                    },
                    error: (error) => {
                        this.spinner.hide();
                        this.dialogRef.close(error);
                    }
                });
        } else {
            this.editForm.markAllAsTouched();
        }
    }

    getCollectionURL(): string {
        return '';
    }

    getCreateFunctionName(): string {
        return '';
    }

    getRouterURL(): string {
        return '';
    }

    getUpdateFunctionName(): string {
        return '';
    }

    close() {
        this.dialogRef.close();
    }

    toggleShowPassword() {
        this.showPassword = !this.showPassword;
    }

    toggleShowConfirmPassword() {
        this.showConfirmPassword = !this.showConfirmPassword;
    }
}
