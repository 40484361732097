import {
    Component,
    HostListener,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardService } from 'ngx-clipboard';
import { of } from 'rxjs';
import { catchError, concatMap, tap } from 'rxjs/operators';
import { BaseComponent } from 'src/app/core/abstractions/base.component';
import { ConfirmDialogComponent } from 'src/app/core/components/confirm-dialog/confirm-dialog.component';
import { BaseService } from '../../../core/abstractions/base-service';
import { InviteEmailSuggestionModalComponent } from './invite-email-suggestion-modal/invite-email-suggestion-modal.component';

@Component({
    selector: 'app-invite-link',
    templateUrl: './invite-link.component.html',
    styleUrls: ['./invite-link.component.scss'],
})
export class InviteLinkComponent extends BaseComponent implements OnInit {
    @Input() generateLinkFunction: string;
    @Input() dropDownTitle: string;
    @Input() dropDownSubtitle: string;
    @Input() dropDownSuggestion: string;
    @Input() buttonTitle: string;
    @Input() modalTitle = 'system.confirm';
    @Input() modalText = 'messages.confirm-renew-invite-link';
    @Input() modalHeight: string;
    @Input() modalWidth: string;
    @Input() disabled = false;

    @ViewChild('drop') drop: NgbDropdown;

    loading = false;
    inviteLink: string;

    @HostListener('document:click', ['$event.target'])
    onClickEmailSuggestion(event) {
        if (event.id === 'suggestionId') {
            this.openEmailTemplateModal();
        }
    }

    constructor(
        private clipboardService: ClipboardService,
        private dialog: MatDialog,
        private sanitizer: DomSanitizer,
        private service: BaseService<any>
    ) {
        super();
    }

    ngOnInit(): void {
        this.getLink(false).subscribe();
    }

    requestLink() {
        this.drop.close();
        this.dialog
            .open(ConfirmDialogComponent, this.getModalConfig())
            .afterClosed()
            .pipe(
                tap(() => this.drop.open()),
                concatMap((confirmed) => {
                    if (confirmed) {
                        return this.getLink(true);
                    }
                    return of({});
                })
            )
            .subscribe();
    }

    copyToClipboard(inviteLink: string) {
        this.clipboardService.copy(inviteLink);
        this.notification.info('messages.link-copied-to-clipboard');
        setTimeout(() => {
            this.drop.close();
        }, 500);
    }

    private getLink(refresh: boolean) {
        this.loading = refresh;
        if (!refresh && this.userService.company && this.userService.company.inviteOptinLink) {
            this.inviteLink = this.userService.company.inviteOptinLink;
            return of();
        }
        return this.service
            .callFunction({ refresh }, this.generateLinkFunction, {
                type: 'callable',
                httpMethod: 'POST',
            })
            .pipe(
                catchError((error) => {
                    this.notification.error(
                        'messages.error-generating-invite-link'
                    );
                    const message = error.error
                        ? error.error.message
                        : error.message;
                    if (message) {
                        this.fireLogginService.sendErrorLog(
                            `An error occurred while generating link with function ${this.generateLinkFunction}, details: ${message}`
                        );
                    } else {
                        this.fireLogginService.sendErrorLog(
                            `An error occurred while generating link with function ${
                                this.generateLinkFunction
                            }, details: ${JSON.stringify(error)}`
                        );
                    }
                    return of({ loading: false });
                }),
                tap((inviteLink) => {
                    this.loading = false;
                    if (inviteLink) {
                        this.inviteLink = inviteLink;
                    }
                })
            );
    }

    private getModalConfig(): MatDialogConfig {
        return {
            height: this.modalHeight,
            width: this.modalWidth,
            panelClass: 'curved-modal',
            data: {
                title: this.modalTitle,
                text: this.modalText,
            },
        };
    }

    getDropDownSuggestion() {
        return this.sanitizer.bypassSecurityTrustHtml(
            this.translateService.instant(this.dropDownSuggestion)
        );
    }

    openEmailTemplateModal() {
        this.drop.close();

        this.dialog
            .open(InviteEmailSuggestionModalComponent, {
                width: '50vw',
                autoFocus: false,
                panelClass: 'curved-modal',
                disableClose: false,
                data: { inviteLink: this.inviteLink },
            })
            .afterClosed()
            .subscribe();
    }
}
