import { Pipe, PipeTransform } from '@angular/core';
import {
    isPossiblePhoneNumber,
    CountryCode,
    parsePhoneNumber,
} from 'libphonenumber-js';

@Pipe({
    name: 'phone',
})
export class PhonePipe implements PipeTransform {
    transform(value: string, defaultCountry?: CountryCode): unknown {
        return value && isPossiblePhoneNumber(value)
            ? parsePhoneNumber(value, defaultCountry).formatInternational()
            : value;
    }
}
