<div [ngClass]="{'form-group': !!label}" *ngIf="form" [formGroup]="form">
    <ng-container *ngIf="label">
        <div class="d-flex align-items-center">
            <span class="required-marker mr-1 mb-1">{{getRequiredMarker()}}</span>
            <label class="w-100">{{label | translate}}</label>
        </div>
    </ng-container>
    <ng-select class="e-select multiple-select"
               appendTo="{{appendTo}}"
               placeholder="{{placeholder | translate}}"
               [items]="items"
               [multiple]="multiple"
               [closeOnSelect]="closeOnSelect"
               [searchable]="searchable"
               [clearable]="clearable"
               [bindLabel]="bindLabel"
               [bindValue]="bindValue"
               [formControlName]="controlName"
               (change)="setItem($event)"
               [readonly]="disabled"
               [searchFn]="searchFn">
        <ng-template ng-label-tmp let-item="item">
            {{getLabel(item) | translate}}
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <span [ngOptionHighlight]="search" [matTooltip]="optionTooltip ? getLabel(item) : null"
                  [matTooltipPosition]="optionTooltipPos" [attr.data-test]="'select-'+getLabel(item)">
                {{ getLabel(item) | translate}}
            </span>
        </ng-template>
    </ng-select>

    <div [class]="getInputComplementClass()" *ngIf="item">
        <app-historical-data-view *ngIf="hasHistoricalDataForThisProperty(item, customApprovalProperty || controlName)"
                                  [item]="item"
                                  [property]="customApprovalProperty || controlName"
                                  [form]="form"
                                  [controlName]="controlName">
        </app-historical-data-view>
        <app-approve-pending-changes *ngIf="hasPendentApprovalForThisProperty(item, customApprovalProperty || controlName)"
                                     [item]="item"
                                     [property]="customApprovalProperty || controlName"
                                     [form]="form"
                                     [controlName]="controlName"
                                     [permissionToApprove]="permissionToApprove">
        </app-approve-pending-changes>
    </div>

    <app-input-error [showRequiredValidator]="showRequiredValidator" [field]="controlName"
                     [formGroup]="form" [showEmpty]="showEmpty"></app-input-error>
</div>

<div [ngClass]="{'form-group': !!label}" *ngIf="!form">
    <ng-container *ngIf="label">
        <div class="d-flex align-items-center">
            <span class="required-marker mr-1 mb-1">{{getRequiredMarker()}}</span>
            <label class="w-100">{{label | translate}}</label>
        </div>
    </ng-container>
    <ng-select class="e-select multiple-select"
               appendTo="{{appendTo}}"
               placeholder="{{placeholder | translate}}"
               [items]="items"
               [multiple]="multiple"
               [closeOnSelect]="closeOnSelect"
               [searchable]="searchable"
               [clearable]="clearable"
               [bindLabel]="bindLabel"
               [bindValue]="bindValue"
               (change)="setItem($event)"
               [searchFn]="searchFn">

        <ng-template ng-label-tmp let-item="item">
            {{getLabel(item) | translate}}
        </ng-template>

        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            <span [ngOptionHighlight]="search" [title]="item?.name" [attr.data-test]="'select-'+getLabel(item)">
                {{ getLabel(item) | translate}}
            </span>
        </ng-template>
    </ng-select>
</div>
