<div ngbDropdown #customDropdown="ngbDropdown" container="body" placement="top-right" class="toggle-drop-container">
    <div class="toggle-drop-anchor" ngbDropdownAnchor (click)="openCustomDropdown(customDropdown)" id="dropdownManual">
        <ng-content></ng-content>
    </div>
    <div class="custom-drop text-center" ngbDropdownMenu aria-labelledby="dropdownManual">
        <div class="d-flex align-items-center flex-column">
            <span>{{confimationMessage}}</span>
            <button class="btn btn-primary confirm-toggle" type="button"
                (click)="confirm()" [disabled]="disabled" translate>system.confirm</button>
        </div>
    </div>
</div>
