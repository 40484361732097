import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'filterPermissions'
})
export class FilterPermissionsFromUserPipe implements PipeTransform {

    constructor(private translateService: TranslateService ) {}

    /**
     * Convert permissions array
     * Example:
     * From
     * [admin#holder#bookkeeping: "kOMP4iZAa8N2Fmdudu1FTyuxr9J3"]
     * To
     * [{permissionName: "Bookkeeping area stand-in", permissionType: "admin"}]
     *
     */
    transform(items: any[], filterArgs: any): any {
        if (!items || !filterArgs) {
            return items;
        }
        const list = [];
        for (const item in items) {
            if (items[item] === filterArgs.userId) {
                list.push(this.convertPermission(item));
            }
        }
        return list;
    }


    private convertPermission(hashAction: string): any {
        const permissionSplited = hashAction?.split('#');
        return {
            permissionType: permissionSplited[0],
            permissionName: this.getTranslateService(permissionSplited)
        };
    }

    private getTranslateService(permissionSplited: string[]) {
        if (permissionSplited[0] === 'admin') {
            return this.translateService.instant(`permissions.${permissionSplited[1]}.${permissionSplited[permissionSplited.length - 1]}`);
        } else {
            return this.translateService.instant(`permissions.${permissionSplited[permissionSplited.length - 1]}`);
        }
    }
}
