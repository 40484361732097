<nav aria-label="Page navigation example" *ngIf="totalPages">
    <ul class="pagination">
        <li class="page-item" [ngClass]="{'page-disabled': currentPage === 0}">
            <a class="page-link" (click)="goToPage(currentPage - 1)" translate>system.back</a>
        </li>
        <li class="page-item ml-3 mr-3">
            <label class="mb-0"> 
                {{'common.pagination' | translate: { currentPage: currentPage + 1,
                    totalPages: totalPages
                } }}
            </label>
        </li>
        <li class="page-item" [ngClass]="{'page-disabled': currentPage === (totalPages-1)}">
            <a class="page-link" (click)="goToPage(currentPage + 1)" translate>system.next</a>
        </li>
    </ul>
</nav>
