import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CREATE_USER_FUNCTION, GENDERS, PERM_FINSTEIN_USER_ADD } from '../../../app.constants';
import { BaseEditComponent } from '../../../core/abstractions/base-edit.component';
import { InputMaskService } from '../../../core/services/input-mask.service';
import { CustomValidators } from '../../../core/util/custom-validators';

@Component({
    selector: 'app-create-user',
    templateUrl: './create-user-modal.component.html',
    styleUrls: ['./create-user-modal.component.scss']
})
export class CreateUserModalComponent extends BaseEditComponent implements OnInit {

    constructor(private dialogRef: MatDialogRef<CreateUserModalComponent>,
                public inputMask: InputMaskService,
                @Inject(MAT_DIALOG_DATA) public data: { readOnly: string }) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    getFormControls() {
        const obj: any = {
            firstName: new UntypedFormControl(undefined, [Validators.required, CustomValidators.noWhitespaceValidator]),
            lastName: new UntypedFormControl(undefined, [Validators.required, CustomValidators.noWhitespaceValidator]),
            email: new UntypedFormControl(undefined, [Validators.required,
                CustomValidators.noWhitespaceValidator, CustomValidators.email]),
            phone: new UntypedFormControl(undefined, [Validators.required, CustomValidators.phone]),
            gender: new FormControl(undefined, [Validators.required]),
            finsteinUser: false
        };
        if (this.data && this.data.readOnly) {
            obj.readOnly = this.data.readOnly;
        }
        return obj;
    }

    submit() {
        if (this.editForm.valid) {
            this.spinner.show();
            const formValue = this.editForm.value;
            formValue.gender = formValue.gender.value;
            formValue.email = formValue.email.toLowerCase();
            formValue.finsteinUser = this.isFinsteinContext();
            this.service.callFunction(formValue, CREATE_USER_FUNCTION, { type: 'callable', httpMethod: 'POST' })
                .subscribe({
                    next: (res) => {
                        this.spinner.hide();
                        this.notification.showToast(res?.code, res?.message);
                        this.dialogRef.close(res.body);
                    },
                    error: (error) => {
                        this.spinner.hide();
                        this.notification.showToast(error?.code, error?.message);
                    }
                });
        } else {
            this.editForm.markAllAsTouched();
        }

    }

    getCollectionURL(): string {
        return '';
    }

    getCreateFunctionName(): string {
        return '';
    }

    getRouterURL(): string {
        return '';
    }

    getUpdateFunctionName(): string {
        return '';
    }

    close() {
        this.dialogRef.close();
    }

    get GENDERS() {
        return GENDERS;
    }

    private isFinsteinContext() {
        return this.userService.hasAnyAuthority(PERM_FINSTEIN_USER_ADD);
    }
}
