<div
    class="form-group"
    (click)="inputFile.click()"
>
    <div class="upload-input" [ngClass]="{ 'invalid': fieldControl.invalid && fieldControl.touched, 'upload-input-content-disabled': this.disabled}"
        [ngStyle]="style?.uploadInput">
        <i class="material-icons-outlined" [ngStyle]="style?.order">file_upload</i>
        <div *ngIf="checkIsImageType()">
            <div class="d-flex align-items-center" *ngIf="checkIsSvgFile() || !fileRef">
                <span class="required-marker mr-1 mb-1">*</span>
                <span>{{ label | translate }}</span>
            </div>
        </div>
        <ng-container *ngIf="fileRef; else elseTemplate">
            <img *ngIf="checkIsImageType()" [ngStyle]="style?.img" [src]="fileRef" />
            <span *ngIf="!checkIsImageType()" appEllipsify translate> {{ fileRef }} </span>
        </ng-container>
        <ng-template #elseTemplate>
            <ng-container *ngIf="fieldControl.invalid && fieldControl.touched; else pristineValue">
                <i class="material-icons text-danger">highlight_off</i>
            </ng-container>
            <ng-template #pristineValue>
                <span translate>system.no-content</span>
            </ng-template>
        </ng-template>
    </div>

    <input
        #inputFile
        type="file"
        [accept]="fileType"
        (change)="onSelectFile($event)"
        class="form-control"
        hidden
        [disabled]="this.disabled"
    />
    <app-input-error
        [showRequiredValidator]="checkIsSvgFile()"
        [field]="field"
        [formGroup]="formGroup"
    >
    </app-input-error>
</div>
