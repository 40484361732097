import { InputFileComponent } from './input-file.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InputErrorModule } from 'src/app/core/components/input-error/input-error.module';
import { TranslateModule } from '@ngx-translate/core';
import { EllipsifyModule } from 'src/app/core/directives/ellipsify/ellipsify.module';

@NgModule({
    declarations: [InputFileComponent],
    imports: [CommonModule, InputErrorModule, TranslateModule, EllipsifyModule],
    exports: [InputFileComponent],
})
export class InputFileModule {}
