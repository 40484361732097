import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import {
    CREATE_REQUEST_OPTIN_FUNCTION,
    GENDERS,
    MAX_AGE_OPTIN,
    MIN_AGE_OPTIN,
} from '../../../app.constants';
import { BaseEditComponent } from '../../../core/abstractions/base-edit.component';
import { InputMaskService } from '../../../core/services/input-mask.service';
import { CustomValidators } from '../../../core/util/custom-validators';

@Component({
    selector: 'app-create-optin',
    templateUrl: './create-optin-modal.component.html',
    styleUrls: ['./create-optin-modal.component.scss'],
})
export class CreateOptinModalComponent
    extends BaseEditComponent
    implements OnInit
{
    // The opt-in must be younger than 80 and older than 18 years old
    minAge = moment().subtract(MAX_AGE_OPTIN, 'year').toDate();
    maxAge = moment()
        .subtract(MIN_AGE_OPTIN, 'year')
        .subtract(1, 'day')
        .toDate();

    constructor(
        private dialogRef: MatDialogRef<CreateOptinModalComponent>,
        public inputMask: InputMaskService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    getFormControls() {
        return {
            firstName: new UntypedFormControl(undefined, [
                Validators.required,
                CustomValidators.noWhitespaceValidator,
            ]),
            lastName: new UntypedFormControl(undefined, [
                Validators.required,
                CustomValidators.noWhitespaceValidator,
            ]),
            email: new UntypedFormControl(undefined, [
                Validators.required,
                CustomValidators.noWhitespaceValidator,
                CustomValidators.email,
            ]),
            phone: new UntypedFormControl(undefined, [
                CustomValidators.phone,
            ]),
            birthday: new UntypedFormControl(null, [Validators.required]),
            gender: new UntypedFormControl(null, [Validators.required]),
        };
    }

    get genders() {
        return GENDERS;
    }

    submit() {
        if (this.editForm.valid) {
            this.spinner.show();
            const formValue = {
                ...this.editForm.value,
                creationType: 'PORTAL',
                companyId: this.userService.companyId,
                lang: this.translateService.currentLang,
            };
            formValue.email = formValue.email.toLowerCase();
            formValue.birthday = moment(formValue.birthday).format(
                'DD.MM.YYYY'
            );
            this.service
                .callFunction([formValue], CREATE_REQUEST_OPTIN_FUNCTION, {
                    type: 'callable',
                    httpMethod: 'POST',
                })
                .subscribe({
                    next: (res) => {
                        this.spinner.hide();
                        this.notification.showToast(res?.code, res?.message);
                        this.dialogRef.close(res.body);
                    },
                    error: (err) => {
                        this.spinner.hide();
                        this.notification.error(
                            err.error.message
                        );
                    }
                });
        } else {
            this.editForm.markAllAsTouched();
        }
    }

    getCollectionURL(): string {
        return '';
    }

    getCreateFunctionName(): string {
        return '';
    }

    getRouterURL(): string {
        return '';
    }

    getUpdateFunctionName(): string {
        return '';
    }

    close() {
        this.dialogRef.close();
    }
}
