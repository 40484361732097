/* src/app/shared/components/cookie-banner/cookie-banner.component.scss */
.cookie-banner {
  width: 98%;
  background-color: #fff;
  position: fixed;
  z-index: 2;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  color: #265ca0;
  line-height: 1.5;
  padding: 30px;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.3), 2px 0px 5px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}
.cookie-banner small {
  font-size: 100%;
}
.btn-primary {
  padding-top: 10px;
  padding-bottom: 10px;
}
.got-it-button {
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 567px) {
  .cookie-banner {
    width: 95%;
  }
}
@media (max-width: 992px) {
  .got-it-button {
    margin-top: 10px;
  }
}
/*# sourceMappingURL=cookie-banner.component-MMNQSKO2.css.map */
