import { Component, EventEmitter, Input, OnInit, Output, ViewChild, } from '@angular/core';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/core/abstractions/base.component';
import { ChangeHistoryMappedModel } from '../../models/change-history.model';

@Component({
    selector: 'app-toggle-drop',
    templateUrl: './toggle-drop.component.html',
    styleUrls: ['./toggle-drop.component.scss'],
})
export class ToggleDropComponent extends BaseComponent implements OnInit {
    private _confimationMessage: string;

    @Input()
    set confimationMessage(inputMessage: string) {
        this._confimationMessage = inputMessage;
    }

    get confimationMessage(): string {
        return this._confimationMessage ? this.translateService.instant(this._confimationMessage) : null;
    }

    @Output()
    confirmAction = new EventEmitter<any>();
    @Output()
    openAction = new EventEmitter<any>();

    @Input()
    open = true;

    @ViewChild('customDropdown', {static: false})
    customDropdown: NgbDropdown;

    @Input()
    disabled = false;

    constructor() {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    confirm() {
        this.customDropdown.close();
        this.confirmAction.emit();
    }

    openCustomDropdown(customDropdown) {
        // Emits the save attempt event
        this.openAction.emit();
        // Only open if allowed
        if (this.open) {
            customDropdown.open();
        }
    }
}
