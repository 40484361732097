/* src/app/shared/components/change-password-dialog/change-password-dialog.component.scss */
.form {
  width: 450px;
}
::ng-deep .mat-mdc-card {
  flex-direction: column !important;
}
.password-group {
  display: flex;
  align-items: center;
  position: relative;
}
.password-group input {
  padding-right: 42px !important;
}
.input-group-text {
  padding-top: 1px !important;
  padding-bottom: 0px !important;
  border: none;
  cursor: pointer;
}
.input-group-append {
  margin-top: 0px !important;
  position: absolute;
  top: 40px;
  right: 22px;
}
:host ::ng-deep mat-card {
  background: rgba(0, 0, 0, 0);
}
:host ::ng-deep mat-card .mat-icon.mat-primary {
  color: #0760ef;
}
:host ::ng-deep .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #0760ef;
  --mdc-linear-progress-track-color: rgba(7, 96, 239, .2);
}
/*# sourceMappingURL=change-password-dialog.component-6WTZMLNJ.css.map */
