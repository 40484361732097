import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FIREBASE_STORAGE_BUCKET_BASE_URL } from 'src/app/app.constants';
import { ToastService } from 'src/app/core/services/toast.service';

type Style = {
    img: object,
    uploadInput: object,
    order: object
};
@Component({
    selector: 'app-input-file[formGroup][field][label]',
    templateUrl: './input-file.component.html',
    styleUrls: ['./input-file.component.scss'],
})
export class InputFileComponent implements OnInit {
    @Input()
    formGroup: UntypedFormGroup;
    @Input()
    field: string;
    @Input()
    label: string;
    @Input()
    fileType: 'image/svg+xml' | 'image/*' | '.zip' = 'image/svg+xml';
    @Input()
    disabled = false;
    @Input()
    alreadyUploaded = false;

    style: Style;

    fileRef: SafeUrl | string;
    currentFile: File;

    @Output() fileSelected = new EventEmitter();

    constructor(
        private sanitizer: DomSanitizer,
        private notification: ToastService
    ) {}

    ngOnInit(): void {
        if (!this.formGroup) {
            throw TypeError(`'formGroup' is required`);
        }
        if (!this.field) {
            throw TypeError(`'field' is required`);
        }
        if (!this.label) {
            throw TypeError(`'label' is required`);
        }
        if (this.alreadyUploaded && !this.fileRef) {
            this.fileRef = 'upload-files.already-uploaded';
        }
        this.defineStyle();
        this.subscribeValueChanges();
    }

    async onSelectFile(event) {
        const selectedFiles: FileList = event.target.files;
        this.currentFile = selectedFiles.item(0);
        const control = this.fieldControl;
        if (this.checkFileType()) {
            if (this.fileType === 'image/svg+xml') {
                const svgValue = await this.currentFile.text();
                control.setValue(svgValue);
            }
            if (this.fileType === 'image/*') {
                const reader = new FileReader();
                reader.readAsDataURL(this.currentFile);
                reader.onload = () => {
                    control.setValue(reader.result);
                };
                this.fileSelected.emit(this.currentFile);
            }
            if (this.fileType === '.zip') {
                control.setValue(this.currentFile.name);
                this.fileSelected.emit(this.currentFile);
            }
        } else {
            let fileType = 'SVG';
            if (this.fileType === 'image/*') {
                fileType = 'IMAGE';
            }
            if (this.fileType === '.zip') {
                fileType = 'ZIP';
            }
            control.setValue(undefined);
            control.markAsDirty();
            control.markAsTouched();
            this.notification.error('service.form.errors.invalid-file-type', {fileType});
        }
    }

    /* Captures events on value changes to set icon/svg value to render in html */
    subscribeValueChanges() {
        this.fieldControl.valueChanges.subscribe((imgSrc) => {
            if (imgSrc) {
                if (this.fileType === 'image/svg+xml') {
                    const svgDiv = document.createElement('div');
                    svgDiv.innerHTML = imgSrc;
                    this.fileRef = this.sanitizer.bypassSecurityTrustUrl(
                        `data:image/svg+xml;base64,${window.btoa(
                            window.unescape(
                                window.encodeURIComponent(
                                    svgDiv.firstElementChild.outerHTML
                                )
                            )
                        )}`
                    );
                }
                if (this.fileType === 'image/*') {
                    this.fileRef = imgSrc.includes(FIREBASE_STORAGE_BUCKET_BASE_URL)
                        || imgSrc.includes(`data:${this.currentFile.type};base64`)
                            ? imgSrc
                            : this.fileRef;
                }
                if (this.fileType === '.zip') {
                    this.fileRef = this.currentFile.name;
                }
            } else {
                this.fileRef = undefined;
            }
        });
    }

    get fieldControl() {
        return this.formGroup.get(this.field);
    }

    checkFileType(): boolean{
        if (this.currentFile?.type === 'image/svg+xml') {
            return true;
        }
        if (this.fileType === 'image/*') {
            return this.currentFile?.type.split('/')[0] === 'image';
        }
        if (this.fileType === '.zip') {
            return this.currentFile?.type === 'application/x-zip-compressed';
        }
        return false;
    }

    checkIsSvgFile(): boolean {
        return this.fileType === 'image/svg+xml';
    }

    checkIsImageType(): boolean {
        return this.fileType === 'image/svg+xml' || this.fileType === 'image/*';
    }

    defineStyle() {
        if (this.checkIsSvgFile()) {
            this.style = {
                img: {
                    height: '40px',
                    width: '40px'
                },
                uploadInput: {
                    padding: '2%'
                },
                order: {
                    order: -1
                }
            };
        }
        else {
            this.style = {
                img: {
                    height: '20px',
                    width: 'auto',
                    'max-width': '150px'
                },
                uploadInput: {
                    padding: '0.32rem 1.6rem 0.32rem 0.75rem'
                },
                order: {
                    order: this.fileType === '.zip' ? 2 : -1
                }
            };
        }
    }
}
