import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';
import * as firestore from 'firebase/firestore';

@Pipe({
    name: 'firebaseDate',
})
export class FirebaseDatePipe implements PipeTransform {
    constructor(@Inject(LOCALE_ID) private locale: string) {}
    transform(
        timestamp: any,
        format = 'dd.MM.yyyy'
    ): string {
        if (timestamp) {
            if (timestamp instanceof firestore.Timestamp) {
                return formatDate(timestamp.toDate(), format, this.locale);
            } else if (timestamp._seconds) {
                timestamp = new Date(timestamp._seconds * 1000);
            }
            return formatDate(timestamp, format, this.locale);
        }
        return '-';
    }
}
