/* src/app/shared/components/invite-link/invite-email-suggestion-modal/invite-email-suggestion-modal.component.scss */
.circle-modal .small-icon {
  font-size: 20px !important;
}
.mdc-dialog__container {
  padding: 24px 0;
}
.mat-mdc-dialog-content {
  padding: 0;
}
.invite-suggestion-container {
  margin: 2rem auto;
  overflow-y: scroll;
}
.invite-suggestion-container .invite-suggestion-header {
  border-bottom: 1px solid #ebebeb;
}
.invite-suggestion-container .invite-suggestion-header p {
  font-weight: 600;
  font-size: 16px;
}
.invite-suggestion-container .invite-suggestion-content {
  margin-top: 1rem;
}
.invite-suggestion-container .invite-suggestion-content .greetings {
  margin-bottom: 0;
}
.invite-suggestion-container .invite-suggestion-content p {
  color: #AEBCC9;
}
.invite-suggestion-container .invite-suggestion-content .portal-link {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}
.invite-suggestion-container .copy-email-template {
  padding: 0.5rem 2rem;
}
@media (max-width: 1550px) and (max-height: 900px) {
  .invite-suggestion-container {
    margin-bottom: 1rem;
    max-height: 400px;
  }
  .invite-suggestion-container .invite-suggestion-header {
    border-bottom: 1px solid #ebebeb;
  }
  .invite-suggestion-container .invite-suggestion-header p {
    font-weight: 600;
    font-size: 14px;
  }
  .invite-suggestion-container .invite-suggestion-content {
    margin-top: 1rem;
  }
  .invite-suggestion-container .invite-suggestion-content .greetings {
    margin-bottom: 0;
  }
  .invite-suggestion-container .invite-suggestion-content p {
    font-size: 14px;
    color: #AEBCC9;
  }
  .invite-suggestion-container .invite-suggestion-content .portal-link {
    color: #007bff;
    text-decoration: underline;
    cursor: pointer;
  }
}
/*# sourceMappingURL=invite-email-suggestion-modal.component-CADO4CCP.css.map */
