import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as lodash from 'lodash';
import { Observable } from 'rxjs';
import { TableColumnModel } from 'src/app/shared/models/table.model';
import * as uuid from 'uuid';
import {
    ACTION_CREATE,
    ACTION_DISABLE,
    ACTION_ENABLE,
    FIREBASE_STORAGE_BUCKET_BASE_URL,
    ITEMS_PER_PAGE,
    LANG_DE,
    STATUS_ACTIVE, STATUS_PENDENT
} from '../../../app.constants';
import { formatDate } from '../../../utils/converter.utils';
import { InputMaskService } from '../../services/input-mask.service';
import { StorageService } from '../../services/storage.service';
import { UserService } from '../../services/user.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { NoticePendentApprovalFoundComponent } from '../notice-pendent-approval-found/notice-pendent-approval-found.component';
import * as firestore from 'firebase/firestore';
import Timestamp = firestore.Timestamp;
import {MatDialog} from '@angular/material/dialog';

declare let $;

type Style = {
    table: {};
    th: {};
    td: {};
    sortableClass: {};
};

type Permission = {
    edit: string[] | string;
    remove: string[] | string;
    view: string[] | string;
};

type CustomAction = {
    onClick: any;
    icon: string;
    isDisabled?: any;
    customStyle?: any;
};

type DropdownCustomAction = {
    onClick: any;
    title: any;
    isDisabled?: any;
    customStyle?: any;
};

type CustomStatusColumn = {
    icon: string;
    label: string;
    iconClass?: string;
};

@Component({
    selector: 'app-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

    public readonly STATUS_PENDENT = STATUS_PENDENT;
    public inputStates: any = {};
    public checkboxRandomId: string;

    @Input() items: any[];
    @Input() columns: any[];
    @Input() pageable = true;
    @Input() permissions: Permission;
    @Input() itemsSelected: any[] = [];
    @Input() forceHideCheckbox = false;
    @Input() disableCheckConditionFunction: any;
    @Input() checkAllOption = true;
    @Input() customActions: any[] = [];
    @Input() dropdownCustomActions: DropdownCustomAction[] = [];
    @Input() customColumn: CustomStatusColumn[] = [];
    @Input() style: Style;
    @Input() bordered = false;
    @Input() currentPage = 0;
    @Input() totalItems = 0;
    @Input() confirmationDialogSwitch = true;
    @Input() textConfirmationEnableDialogSwitch = 'operations.enable.confirmation';
    @Input() textConfirmationDisableDialogSwitch = 'operations.disable.confirmation';
    @Input() permissionToApprove: string;
    @Input() verifyIsChecked: boolean;
    @Input() viewMode = false;
    @Input() switchLabel = 'common.active';

    @Output() approvalDetails = new EventEmitter();
    @Output() edit = new EventEmitter();
    @Output() changeStatus = new EventEmitter();
    @Output() view = new EventEmitter();
    @Output() check = new EventEmitter();
    @Output() changeSort = new EventEmitter();
    @Output() paginationChange = new EventEmitter();
    @Output() switch = new EventEmitter();

    @Input() disableConfirmationText = 'operations.disable.confirmation';
    @Input() enableConfirmationText = 'operations.enable.confirmation';

    @Input() disableEditBtnByStatus = [];
    @Input() disableViewBtnByStatus = [];
    @Input() disableDeleteBtnByStatus = [];

    public removeId: string;

    constructor(protected userService: UserService,
                public dialog: MatDialog,
                protected translateService: TranslateService,
                protected inputMask: InputMaskService,
                protected storage: StorageService) {
    }

    ngOnInit(): void {
        if (this.hasSwitchAction()) {
            this.columns.push({
                property: 'active',
                label: this.switchLabel,
                sortable: false
            });
        }
        this.initInputStates();
        if (this.hasRowSelection()) {
            this.checkboxRandomId = uuid.v4();
        }
    }

    get pageSize() {
        return ITEMS_PER_PAGE;
    }

    onEdit(item) {
        this.edit.emit(item);
    }

    onPaginationChanged(newPage: number) {
        this.paginationChange.emit(newPage);
    }

    onSwitch(item): Observable<any> {
        if (this.confirmationDialogSwitch) {
            return new Observable(observer => {
                this.dialog.open(ConfirmDialogComponent, {
                    panelClass: 'curved-modal',
                    data: {
                        title: item.name,
                        text: item.status !== STATUS_ACTIVE
                            ? this.textConfirmationEnableDialogSwitch
                            : this.textConfirmationDisableDialogSwitch,
                    }
                }).afterClosed().subscribe((confirmed) => {
                    if (confirmed) {
                        this.switch.emit({
                            data: item, isChecked: this.isChecked(item)
                        });
                    }
                    observer.next(confirmed);
                });
            });
        } else {
            return new Observable(observer => {
                this.switch.emit({
                    data: item, isChecked: this.isChecked(item)
                });
                observer.next();
            });
        }
    }

    onView(item) {
        this.view.emit(item);
    }

    onApprovalDetails(item) {
        this.approvalDetails.emit(item);
    }

    onChangeSort({active, direction}) {
        this.changeSort.emit({column: active, sort: direction});
    }

    hasAuthorityToEdit() {
        if (this.permissions && this.permissions.edit) {
            return this.userService.hasAnyAuthority(this.permissions.edit);
        }
        return true;
    }

    onRowSelect(item) {
        const index = this.itemsSelected?.findIndex(it => it.id === item.id);
        if (index === -1) {
            this.itemsSelected.push(item);
        } else {
            this.itemsSelected.splice(index, 1);
        }
        this.check.emit(this.itemsSelected);
    }

    selectAll() {
        this.itemsSelected = this.itemsSelected.length < this.items.length ?
            lodash.cloneDeep(this.items) : [];
        this.check.emit(this.itemsSelected);
    }

    hasAuthorityToRemove() {
        if (this.permissions && this.permissions.remove) {
            return this.userService.hasAnyAuthority(this.permissions.remove);
        }
        return true;
    }

    hasAuthorityToView() {
        if (this.permissions && this.permissions.view) {
            return this.userService.hasAnyAuthority(this.permissions.view);
        }
        return true;
    }

    hasAnyPendentApproval(item) {
        return item.pendentApproval;
    }

    hasPendentApprovalForCreateAction(item) {
        return item.pendentApproval && item.pendentApproval.action === ACTION_CREATE;
    }

    hasPendentApprovalForThisProperty(item, column) {
        return item.pendentApproval && item.pendentApproval.backup && item.pendentApproval.action !== ACTION_CREATE &&
            Object.keys(item.pendentApproval.backup).includes(column.property);
    }

    hasEditAction() {
        return this.edit.observers.length > 0;
    }

    hasRemoveAction() {
        return this.changeStatus.observers.length > 0;
    }

    hasViewAction() {
        return this.view.observers.length > 0;
    }

    hasRowSelection() {
        return this.check.observers.length > 0 && !this.forceHideCheckbox;
    }

    hasSwitchAction() {
        return this.switch.observers.length > 0;
    }

    hasAnyAction() {
        return this.hasEditAction() || this.hasRemoveAction() || this.hasViewAction() || this.customActions.length > 0
            || this.dropdownCustomActions.length > 0;
    }

    hasAnyDropdownAction() {
        return this.dropdownCustomActions.length > 0;
    }

    hasActiveColumn(column) {
        return column?.property === 'active';
    }

    hasAnyError(item?: any) {
        if (item) {
            return item && !!item.errorReport;
        } else {
            return !!this.items.filter(data => !!data.errorReport).length;
        }
    }

    hasCustomStatusColumn(column) {
        return column?.customStatusColumn;
    }

    isCheckDisabled(item) {
        return this.disableCheckConditionFunction && this.disableCheckConditionFunction(item);
    }

    itemToChangeStatus(item: any) {
        const action = item.status === STATUS_ACTIVE ? ACTION_DISABLE : ACTION_ENABLE;
        this.removeId = item.id;
        if (this.hasAnyPendentApproval(item)) {
            this.dialog.open(NoticePendentApprovalFoundComponent);
        } else {
            this.dialog.open(ConfirmDialogComponent, {
                panelClass: 'curved-modal',
                data: {
                    text: action === ACTION_DISABLE ? this.disableConfirmationText : this.enableConfirmationText
                }
            }).afterClosed().subscribe((confirmed) => {
                if (confirmed) {
                    this.changeStatus.emit(item);
                }
            });
        }
    }

    onClickValue(item, column) {
        if (column.onClick) {
            column.onClick(item);
        }
    }

    getColumnValue(item, column) {
        const value = lodash.get(item, column.property);
        if (column.property === 'status') {
            let status = item.status;
            if (column.getTranslatedText) {
                status = column.getTranslatedText(item) || status;
            }
            // status = STATUS_LABELS[(status?.replace(/ /g, '_'))] || status;

            return this.translateService.instant(status);
        } else if (value instanceof Date || value instanceof Timestamp) {
            return column.getTranslatedText ? column.getTranslatedText(item) : formatDate(value, this.inputMask.dateMaskFormat);
        } else if (column.fixedValue) {
            return column.getTranslatedText ? column.getTranslatedText(item) : column.fixedValue;
        } else if (column.propertyDe && column.propertyEn) {
            if (this.getPropretyLanguage() === LANG_DE) {
                return item[column.propertyDe];
            } else {
                return item[column.propertyEn];
            }
        } else if (column.money) {
            let moneyValue = value;
            if (column.getTranslatedText) {
                moneyValue = column.getTranslatedText(item);
            }
            return moneyValue.toLocaleString('de-DE', { style: 'currency', currency: 'EUR', currencyDisplay: 'symbol' });
        } else if (column.translateValue) {
            return this.translate(column.getTranslatedText ? column.getTranslatedText(item) : value);
        } else {
            if (typeof value === 'boolean') {
                return value ? this.translate('system.yes') : this.translate('system.no');
            }
            return column.getTranslatedText ? column.getTranslatedText(item) : value;
        }
    }

    changeInputState(column: any, index: number, idElement?: string) {
        this.inputStates[column.property][index] = !this.inputStates[column.property][index];
        if (this.inputStates[column.property][index] && idElement && column.select) {
            setTimeout(() => {
                $(`#${idElement} > div > div > div > input`).focus();
            }, 0);
        } else if (this.inputStates[column.property][index] && idElement) {
            setTimeout(() => {
                document.getElementById(idElement).focus();
            }, 0);
        }
    }

    disableInput(column: any, index: number) {
        this.inputStates[column.property][index] = false;
    }

    getCssClass(column: TableColumnModel, item) {
        let className = '';
        if (column.onClick) {
            className += 'link ';
        }
        if (column.property === 'status') {
            className += 'cursor-default badge mt-3 ' + item.status;
        }
        /* Each implementation defines the rule of applying the class to the field; */
        if (column.getClassName) {
            className = column.getClassName(item);
        }

        return className?.trim();
    }

    isAllChecked() {
        return this.itemsSelected?.length === this.items?.length;
    }

    isChecked(item) {
        return !!this.itemsSelected?.find((it) => it.id === item.id);
    }

    translate(key: string) {
        return this.translateService.instant(key);
    }

    isCustomActionDisabled(customAction: CustomAction | DropdownCustomAction, item: any) {
        if (customAction.isDisabled) {
            return customAction.isDisabled(item);
        }
        return false;
    }

    getCustomStyle(customAction: CustomAction, item: any) {
        if (customAction.customStyle) {
            return customAction.customStyle(item);
        }
        return '';
    }

    getColumnStyle(column: any, item: any) {
        if (column.style) {
            if (lodash.isFunction(column.style)) {
                return column.style(item);
            } else if (column && column.style && this.style) {
                return {...this.style?.td, ...column.style};
            }
        }
    }

    getCustomStatusColumn(item, column): CustomStatusColumn {
        const value = lodash.get(item, column.property);
        return lodash.get(column.statusList, value);
    }

    hasPendentApproval(item) {
        return !this.userService.loggedUser.finsteinUser && item && item.pendentApproval;
    }

    getTooltip(item: any, column: TableColumnModel) {
        if (column.tooltip) {
            return lodash.isFunction(column.tooltip) ? column.tooltip(item) : column.tooltip;
        }
    }

    private initInputStates() {
        for (const column of this.columns) {
            if (column.input || column.taggerInput || column.select) {
                this.inputStates[column.property] = [];
            }
        }
    }

    /**
     * Method responsible for mapping the items in loop button.
     *
     * @param index - button position;
     * @param item - item ( any )
     *
     * @returns unique identifier;
     */
    trackButton(index, item) {
        return `${index}-button`;
    }

    getPropretyLanguage() {
        return this.translateService.currentLang;
    }

    getIconURL(iconItemUrl) {
        return `${FIREBASE_STORAGE_BUCKET_BASE_URL}${iconItemUrl}`;
    }

    isStatusDisabledItem(list, item) {
        return list.includes(item.status);
    }
}
