/* src/app/core/components/pagination/pagination.component.scss */
.page-disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.pagination {
  justify-content: center;
  align-items: center;
}
/*# sourceMappingURL=pagination.component-Y752TM3I.css.map */
