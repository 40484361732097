/* src/app/shared/components/create-company-canteen-modal/create-company-canteen.component.scss */
.page-title {
  font-size: 1rem;
  text-transform: uppercase;
  color: #6c757d;
}
.btn-primary {
  background: #0760ef !important;
}
.mat-dialog-actions {
  right: 4%;
  position: relative;
}
.overflow-y {
  max-height: 49vh;
  overflow-y: auto;
  overflow-x: hidden;
}
/*# sourceMappingURL=create-company-canteen.component-4EMKSEWM.css.map */
