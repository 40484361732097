/* src/app/core/components/select/select.component.scss */
::ng-deep .ng-select.ng-select-disabled > .ng-select-container {
  background-color: #e9ecef;
}
.icon-required {
  position: absolute;
  color: #dc3545;
  right: 15%;
  top: 36%;
  z-index: 1;
}
label {
  white-space: nowrap;
}
ng-select.ng-touched.ng-invalid ::ng-deep .ng-select-container {
  border-color: #dc3545;
}
ng-select.ng-touched.ng-invalid ::ng-deep .ng-arrow {
  border-color: #dc3545 transparent transparent;
}
.select-input-complement-with-label {
  position: absolute;
  right: 1.75rem;
  top: 2.375rem;
  cursor: pointer;
  pointer-events: auto;
}
.select-input-complement-without-label {
  cursor: pointer;
  pointer-events: auto;
  position: absolute;
  top: 50%;
  right: 1.75rem;
  margin: auto;
  transform: translateY(-50%);
}
@media (max-width: 1900px) {
  .select-input-complement-with-label {
    right: 1.75rem;
    top: calc(13px + 1.25rem);
  }
}
/*# sourceMappingURL=select.component-TOS2BWO5.css.map */
