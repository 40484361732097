
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from "@angular/material/input";
import { TranslateModule } from '@ngx-translate/core';
import { BaseModalModule } from "../../../core/components/base-modal/base-modal.module";
import { InputModule } from "../../../core/components/input/input.module";
import { SelectModule } from "../../../core/components/select/select.module";
import { ChangePasswordDialogComponent } from './change-password-dialog.component';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';


@NgModule({
    declarations: [ChangePasswordDialogComponent],
    imports: [
        CommonModule,
        TranslateModule,
        MatCheckboxModule,
        FormsModule,
        BaseModalModule,
        InputModule,
        MatInputModule,
        MatPasswordStrengthModule,
        SelectModule,
        ReactiveFormsModule
    ],
    exports: [ChangePasswordDialogComponent]
})
export class ChangePasswordDialogModule {
}
