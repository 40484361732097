import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {CookieBannerComponent} from './cookie-banner.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule
    ],
    declarations: [CookieBannerComponent],
    exports: [CookieBannerComponent],
})
export class CookieBannerModule {
}
