import { HistoricalDataModule } from '../historical-data/historical-data.module';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgOptionHighlightModule} from '@ng-select/ng-option-highlight';
import {CreateCompanyUnitComponent} from './create-company-unit.component';
import {ReactiveFormsModule} from '@angular/forms';
import {InputModule} from '../../../core/components/input/input.module';
import { ApprovePendingChangesModule } from '../approve-pending-changes/approve-pending-changes.module';
import { SelectModule } from '../../../core/components/select/select.module';
import {FinsteinTooltipModule} from '../../../core/directives/finstein-tooltip/finstein-tooltip.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
    declarations: [
        CreateCompanyUnitComponent
    ],
    imports: [
        TranslateModule,
        CommonModule,
        NgSelectModule,
        NgOptionHighlightModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        TranslateModule,
        ReactiveFormsModule,
        InputModule,
        SelectModule,
        ApprovePendingChangesModule,
        HistoricalDataModule,
        FinsteinTooltipModule,
        MatTooltipModule
    ],
    exports: [
        CreateCompanyUnitComponent
    ]
})
export class CreateCompanyUnitModule {
}
