import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {CookieDialogComponent} from './cookie-dialog.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {FormsModule} from '@angular/forms';


@NgModule({
    declarations: [CookieDialogComponent],
    imports: [
        CommonModule, TranslateModule, MatCheckboxModule, FormsModule
    ],
    exports: [CookieDialogComponent]
})
export class CookieDialogModule {
}
