import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomValidators } from 'src/app/core/util/custom-validators';
import {
    ADDRESSES_COLLECTION,
    APPROVE_REQUESTS_FOR_COMPANY_MASTER_DATA_FUNCTION,
    COMPANIES_COLLECTION,
    PERM_EDIT_COMPANY_MASTER_DATA,
    STATES,
    STATUS_APPROVED,
    STATUS_REJECTED,
} from '../../../app.constants';
import { BaseEditComponent } from '../../../core/abstractions/base-edit.component';
import { RejectionMessageModalComponent } from '../../../core/components/rejection-message-modal/rejection-message-modal.component';

@Component({
    templateUrl: './create-company-unit.component.html',
    styleUrls: ['./create-company-unit.component.scss'],
})
export class CreateCompanyUnitComponent
    extends BaseEditComponent
    implements OnInit
{
    item: any;
    pendentApproval;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { entity; isViewMode },
        private dialogRef: MatDialogRef<CreateCompanyUnitComponent>
    ) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
        if (this.data.entity) {
            this.item = this.data.entity;
            this.editForm.patchValue(this.data.entity);
            this.pendentApproval = this.data.entity.pendentApproval;
            if (this.pendentApproval?._?.type === 'document') {
                this.pendentApproval = this.pendentApproval
                    .get()
                    .then((res) => ({ id: res.id, ...res.data() }));
            }
            if (this.pendentApproval && this.hasPermissionToConfirm()) {
                this.isViewMode = true;
                this.editForm.disable();
            }
        }
        if (this.data.isViewMode) {
            this.isViewMode = this.data.isViewMode;
            this.editForm.disable();
        }
    }

    private hasPermissionToConfirm() {
        return this.userService.hasAnyAuthority([
            `confirm#holder#${PERM_EDIT_COMPANY_MASTER_DATA}`,
            `confirm#standIn#${PERM_EDIT_COMPANY_MASTER_DATA}`,
        ]);
    }

    get EDIT_COMPANY_MASTER_DATA() {
        return PERM_EDIT_COMPANY_MASTER_DATA;
    }

    get states() {
        return STATES;
    }

    getTitle() {
        return this.isViewMode
            ? 'companies.units.details'
            : this.isEditMode
            ? 'companies.units.edit'
            : 'companies.units.register';
    }

    getFormControls() {
        return {
            internalRegistration: new UntypedFormControl(undefined, []),
            street: new UntypedFormControl(undefined, [Validators.required]),
            additional: new UntypedFormControl(undefined, []),
            city: new UntypedFormControl(undefined, [Validators.required]),
            zipCode: new UntypedFormControl(undefined, [
                Validators.required,
                CustomValidators.noWhitespaceValidator,
                CustomValidators.onlyPositiveNumbers,
            ]),
            state: new UntypedFormControl(undefined, [Validators.required]),
            addressNumber: new UntypedFormControl(undefined, [
                Validators.required,
                CustomValidators.noWhitespaceValidator,
            ]),
            country: new UntypedFormControl(undefined, [Validators.required]),
            mailBoxNumber: new UntypedFormControl(undefined, []),
        };
    }

    submit() {
        if (this.editForm.valid) {
            this.dialogRef.close({ id: this.item.id, ...this.editForm.value });
        } else {
            this.notification.error('operations.insert.review-required-fields');
            this.editForm.markAllAsTouched();
        }
    }

    reject() {
        this.dialog
            .open(RejectionMessageModalComponent, {
                panelClass: 'curved-modal',
                width: '40vw',
            })
            .afterClosed()
            .subscribe((result: any) => {
                if (result.submit) {
                    this.postFeedback(STATUS_REJECTED, result.message);
                }
            });
    }

    approve() {
        this.postFeedback(STATUS_APPROVED);
    }

    postFeedback(feedback: string, message = '') {
        this.spinner.show();
        const result: any = {
            approvalRequestId: this.item.pendentApproval.id,
            action: this.item.pendentApproval.action,
            reference: this.item.pendentApproval.reference.path,
            requestedBy: this.item.pendentApproval.requestedBy.path,
            historyRef: this.item.pendentApproval.historyRef.path,
            comment: message,
            status: feedback,
        };
        this.service
            .callFunction(
                result,
                APPROVE_REQUESTS_FOR_COMPANY_MASTER_DATA_FUNCTION,
                { type: 'callable', httpMethod: 'POST' }
            )
            .subscribe({
                next: () => {
                    this.dialogRef.close();
                    this.spinner.hide();
                    this.notification.updateSuccess();
                },
                error: () => {
                    this.spinner.hide();
                    this.notification.updateError();
                },
            });
    }

    getCollectionURL(): string {
        return `${COMPANIES_COLLECTION}/${this.userService.loggedUser.companyId}/${ADDRESSES_COLLECTION}`;
    }

    getCreateFunctionName(): string {
        return '';
    }

    getRouterURL(): string {
        return '';
    }

    getUpdateFunctionName(): string {
        return '';
    }

    dialogClose() {
        this.dialogRef.close();
    }

    hasPendentApproval() {
        return (
            this.item &&
            this.item.pendentApproval &&
            ['DISABLE', 'CREATE', 'UPDATE'].includes(
                this.item.pendentApproval.action
            ) &&
            this.hasPermissionToConfirm()
        );
    }

    getTitleForPendentApproval() {
        if (this.item && this.item.pendentApproval) {
            return this.item.pendentApproval.action === 'DISABLE'
                ? this.translate(
                      'approvals-requests.alert-approval-request.info-document-disabled'
                  )
                : this.translate(
                      'approvals-requests.alert-approval-request.info-new-document-created'
                  );
        }
    }

    getCssClassForPendentApprovalTitle() {
        if (this.item && this.item.pendentApproval) {
            return this.item.pendentApproval.action === 'DISABLE'
                ? 'text-danger'
                : 'text-info';
        }
    }

    getInputClassDisabled(item: any, property: string) {
        return this.hasPendentApprovalForThisProperty(item, property)
            ? 'disabled'
            : '';
    }

    hasPendentApprovalForThisProperty(item, property) {
        if (item && item.pendentApproval && item.pendentApproval.data) {
            return item.pendentApproval.data.find(
                (data) => data.property === property
            );
        }
    }
}
