<div class="row justify-content-center">
    <span class="circle-modal animate-grow-circle">
        <i class="material-icons edit-icon">edit</i>
    </span>
</div>
<i class="close material-icons-outlined" (click)="dialogClose()">close</i>

<div class="pl-lg-8 pr-lg-8 pt-lg-6" mat-dialog-content>
    <div class="row d-flex justify-content-center align-content-center mb-6">
        <span *ngIf="!hasPendentApproval()" class="text-justify page-title">
            {{getTitle() | translate}}
        </span>
        <span *ngIf="hasPendentApproval()" class="pendent-approval-title {{getCssClassForPendentApprovalTitle()}}"
              translate>
            {{getTitleForPendentApproval()}}
        </span>
    </div>

    <div class="row justify-content-center overflow-y">
        <form [formGroup]="editForm" id="form">
            <div class="row">
                <div class="col-md-4 col-sm-12">
                    <div class="form-group mb-0 d-flex align-items-center position-relative">
                        <app-input class="w-100"
                                   label="companies.internal-registration"
                                   controlName="internalRegistration"
                                   [enableTooltip]="false"
                                   [hasPendentApproval]="hasPendentApprovalForThisProperty(item, 'internalRegistration')"
                                   [form]="editForm"
                                   placeholder="companies.internal-registration"
                                   [item]="item"
                                   [permissionToApprove]="EDIT_COMPANY_MASTER_DATA">
                            <span additionalLabel class="material-icons white pl-2 mb-2 cursor-default"
                                  [matTooltip]="'companies.tooltips.internal-registration' | translate">info</span>
                        </app-input>
                    </div>
                </div>

                <div class="col-md-4 col-sm-12">
                    <div class="form-group mb-0 d-flex align-items-center position-relative">
                        <app-input class="w-100"
                                   label="common.street"
                                   [hasPendentApproval]="hasPendentApprovalForThisProperty(item, 'street')"
                                   [form]="editForm"
                                   controlName="street"
                                   placeholder="common.placeholders.street"
                                   [item]="item"
                                   [permissionToApprove]="EDIT_COMPANY_MASTER_DATA">
                        </app-input>
                    </div>
                </div>

                <div class="col--12 col-lg-4">
                    <div class="form-group mb-0 d-flex align-items-center position-relative">
                        <app-input class="w-100"
                                   label="common.address-number"
                                   [hasPendentApproval]="hasPendentApprovalForThisProperty(item, 'addressNumber')"
                                   [form]="editForm"
                                   controlName="addressNumber"
                                   placeholder="common.placeholders.address-number"
                                   [item]="item"
                                   [permissionToApprove]="EDIT_COMPANY_MASTER_DATA">
                        </app-input>
                    </div>
                </div>

                <div class="col--12 col-lg-4">
                    <div class="form-group mb-0 d-flex align-items-center position-relative">
                        <app-input class="w-100"
                                   label="common.additional"
                                   [hasPendentApproval]="hasPendentApprovalForThisProperty(item, 'additional')"
                                   [form]="editForm"
                                   controlName="additional"
                                   placeholder="common.placeholders.additional"
                                   [item]="item"
                                   [permissionToApprove]="EDIT_COMPANY_MASTER_DATA">
                        </app-input>
                    </div>
                </div>

                <div class="col-md-4 col-sm-12">
                    <div class="form-group mb-0 d-flex align-items-center position-relative">
                        <app-input class="w-100"
                                   type="number"
                                   label="common.zip-code"
                                   [hasPendentApproval]="hasPendentApprovalForThisProperty(item, 'zipCode')"
                                   [form]="editForm" controlName="zipCode"
                                   placeholder="common.placeholders.zip-code"
                                   [item]="item"
                                   [permissionToApprove]="EDIT_COMPANY_MASTER_DATA">
                        </app-input>
                    </div>
                </div>

                <div class="col--12 col-lg-4">
                    <div class="form-group mb-0 d-flex align-items-center position-relative">
                        <app-input class="w-100"
                                   label="common.city"
                                   [hasPendentApproval]="hasPendentApprovalForThisProperty(item, 'city')"
                                   [form]="editForm" controlName="city"
                                   placeholder="common.placeholders.city"
                                   [item]="item"
                                   [permissionToApprove]="EDIT_COMPANY_MASTER_DATA">
                        </app-input>
                    </div>
                </div>

                <div class="col-md-4 col-sm-12 d-flex align-items-center position-relative">
                    <app-select class="w-100"
                                label="common.state"
                                controlName="state"
                                [form]="editForm"
                                [hasPendentApproval]="hasPendentApprovalForThisProperty(item, 'state')"
                                appendTo=".circle-modal"
                                placeholder="common.please-select"
                                [items]="states"
                                [multiple]="false"
                                [ngClass]="getInputClassDisabled(item, 'state')"
                                [item]="item"
                                [permissionToApprove]="EDIT_COMPANY_MASTER_DATA">
                    </app-select>
                </div>

                <div class="col-md-4 col-sm-12">
                    <div class="form-group mb-0 d-flex align-items-center position-relative">
                        <app-input class="w-100"
                                   label="common.country"
                                   [hasPendentApproval]="hasPendentApprovalForThisProperty(item, 'country')"
                                   [form]="editForm" controlName="country"
                                   placeholder="common.placeholders.country"
                                   [item]="item"
                                   [permissionToApprove]="EDIT_COMPANY_MASTER_DATA">
                        </app-input>
                    </div>
                </div>

                <div class="col-md-4 col-sm-12">
                    <div class="form-group mb-0 d-flex align-items-center position-relative">
                        <app-input class="w-100"
                                   label="companies.mail-box-number"
                                   [hasPendentApproval]="hasPendentApprovalForThisProperty(item, 'mailBoxNumber')"
                                   [form]="editForm"
                                   controlName="mailBoxNumber"
                                   placeholder="common.placeholders.mail-box-number"
                                   type="number"
                                   [item]="item"
                                   [permissionToApprove]="EDIT_COMPANY_MASTER_DATA">
                        </app-input>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="justify-content-end" mat-dialog-actions>
    <ng-container *ngIf="hasPendentApproval(); else elseTemplate">
        <button type="button" form="form" class="btn btn-danger mr-3" (click)="reject()" translate>
            common.reject
        </button>

        <button type="button" form="form" class="btn btn-success" (click)="approve()" translate>
            common.approve
        </button>
    </ng-container>
    <ng-template #elseTemplate>
        <button *ngIf="!isViewMode" type="button" form="form" class="btn btn-primary"
                (click)="submit()" translate>
            system.save
        </button>
    </ng-template>
</div>
