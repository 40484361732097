import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToggleDropComponent } from './toggle-drop.component';

@NgModule({
    declarations: [ToggleDropComponent],
    imports: [CommonModule, TranslateModule, NgbDropdownModule],
    exports: [ToggleDropComponent],
})
export class ToggleDropModule {}
