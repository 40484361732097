<finstein-base-modal title="users.update-password" actionPosition="end">
    <finstein-circle-warn header></finstein-circle-warn>
    <div content>
        <form [formGroup]="editForm" id="form-password" class="form">
            <div class="row mt-3 mb-3">
                <div class="col-12">
                    <div class="d-flex align-items-center">
                        <label appEllipsify class="w-100">{{ 'users.new-password' | translate }}</label>
                        <ng-content select="[additionalLabel]"></ng-content>
                    </div>
                    
                    <input class="form-control mb-2" [type]="showPassword ? 'text' : 'password'" [formControlName]="'password'" matInput #password
                            placeholder="{{'users.new-password' | translate}}"/>

                    <div class="input-group-append copy">
                        <span class="input-group-text">
                            <i class="material-icons" (click)="toggleShowPassword()">
                                {{ showPassword ? 'visibility' : 'visibility_off' }}
                            </i>
                        </span>
                    </div>
                

                    <mat-password-strength #passwordComponent [password]="password.value"></mat-password-strength>
                    <mat-password-strength-info
                        [lowerCaseCriteriaMsg]="'users.criterias.lowercase' | translate"
                        [upperCaseCriteriaMsg]="'users.criterias.uppercase' | translate"
                        [digitsCriteriaMsg]="'users.criterias.digits' | translate"
                        [specialCharsCriteriaMsg]="'users.criterias.special-chars' | translate"
                        [minCharsCriteriaMsg]="'users.criterias.min-chars' | translate"
                        [passwordComponent]="passwordComponent"
                    ></mat-password-strength-info>
                </div>
            </div>

            <div class="row mt-3 mb-3">
                <div class="col-12">
                    <app-input type="password" [type]="showConfirmPassword ? 'text' : 'password'" label="users.confirm-new-password" [form]="editForm"
                               controlName="confirmPassword" [item]="item"
                               placeholder="users.confirm-new-password">
                    </app-input>
                    <div class="input-group-append copy">
                        <span class="input-group-text">
                            <i class="material-icons" (click)="toggleShowConfirmPassword()">
                                {{ showConfirmPassword ? 'visibility' : 'visibility_off' }}
                            </i>
                        </span>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <finstein-action-form-buttons actions (save)="submit()" (cancel)="close()"></finstein-action-form-buttons>
</finstein-base-modal>
