<finstein-base-modal title="action-sequence.create-user.record-person" actionPosition="end">
    <finstein-circle-edit header></finstein-circle-edit>
    <div content>
        <form [formGroup]="editForm" autocomplete="off">
            <div class="row">
                <app-input class="col-sm-6"
                           data-test="create-user-modal-first-name"
                           [form]="editForm"
                           controlName="firstName"
                           placeholder="common.placeholders.first-name"
                           label="users.firstName">
                </app-input>

                <app-input class="col-sm-6"
                           data-test="create-user-modal-last-name"
                           [form]="editForm"
                           controlName="lastName"
                           placeholder="common.placeholders.last-name"
                           label="users.lastName">
                </app-input>

                <app-input class="col-sm-6"
                           data-test="create-user-modal-email"
                           [form]="editForm"
                           type="email"
                           controlName="email"
                           placeholder="common.placeholders.email"
                           label="common.email">
                </app-input>

                <app-input class="col-sm-6" type="tel"
                           data-test="create-user-modal-phone"
                           [form]="editForm"
                           controlName="phone"
                           label="common.phone"
                           [placeholder]="inputMask.phonePlaceholder">
                </app-input>

                <div class="col-sm-6">
                    <app-select controlName="gender"
                                data-test="create-user-modal-genders-select"
                                appendTo="body"
                                label="common.gender-label"
                                [form]="editForm"
                                [items]="GENDERS"
                                bindLabel="value"
                                [multiple]="false"
                                placeholder="common.gender-label"
                                [item]="item"
                                [searchable]="false"
                                showProperty="label">
                    </app-select>
                </div>
            </div>
        </form>
        <span class="mt-3" translate>action-sequence.create-user.description</span>
    </div>
    <finstein-action-form-buttons data-test="create-user-modal-action-buttons" actions (save)="submit()" (cancel)="close()"></finstein-action-form-buttons>
</finstein-base-modal>
