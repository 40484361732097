import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BaseModalModule } from '../base-modal/base-modal.module';
import { ConfirmDialogComponent } from './confirm-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        BaseModalModule
    ],
    declarations: [ConfirmDialogComponent],
    exports: [ConfirmDialogComponent]
})
export class ConfirmDialogModule {
}
