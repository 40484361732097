<div class="row pb-6">
    <table class="table dataTable table-vmiddle table-borderless">
        <thead class="header-table">
        <tr>
            <th scope="col">
                <span class="property" translate>common.zip-code</span>
            </th>
            <th scope="col">
                <span class="property" translate>common.street</span>
            </th>
            <th scope="col">
                <span class="property" translate>common.address-number</span>
            </th>
            <th scope="col">
                <span class="property" translate>common.city</span>
            </th>
            <th scope="col">
                <span class="property" translate>common.country</span>
            </th>
            <th scope="col">
                <span class="property" translate>common.state</span>
            </th>
            <th scope="col">
                <span class="property" translate>companies.mail-box-number</span>
            </th>
        </tr>
        </thead>

        <tbody>
        <ng-container *ngFor="let item of items; let index = index">
            <tr>
                <ng-container *ngFor="let column of columnNames">
                    <td class="position-relative">
                            <span appEllipsify>
                                {{item[column]}}
                            </span>
                    </td>
                </ng-container>
                <td *ngIf="!isViewMode">
                    <button *ngIf="!hasPendentApproval(item) && !actionsDisabled" class="btn-action" type="button"
                            (click)="edit(index)">
                        <i class="material-icons">create</i>
                    </button>
                    <app-approve-pending-changes *ngIf="hasPendentApproval(item)"
                                                 [item]="item"
                                                 (details)="onApproveDetails(index)"
                                                 [permissionToApprove]="EDIT_COMPANY_MASTER_DATA">
                    </app-approve-pending-changes>
                    <button *ngIf="!hasPendentApproval(item) && !actionsDisabled" class="btn-action" type="button"
                            (click)="remove(item)">
                        <i class="material-icons">delete</i>
                    </button>
                </td>
                <td class="position-relative">
                    <app-historical-document-view *ngIf="hasHistoricalData(item)"
                                                  class="historical-icon"
                                                  [item]="item">
                    </app-historical-document-view>
                </td>
            </tr>
        </ng-container>
        </tbody>

    </table>
</div>
