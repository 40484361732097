<div rightContent class="invite-link-dropdown-container">
    <div ngbDropdown container="body" placement="bottom-right" #drop="ngbDropdown" class="d-inline-block">
        <button
            class="btn btn-outline-primary rounded"
            id="invite-link-dropdown"
            ngbDropdownToggle
            [disabled]="disabled"
            translate>
            {{buttonTitle}}
        </button>
        <div ngbDropdownMenu aria-labelledby="invite-link-dropdown"
            class="invite-link-dropdown-menu mt-1">
            <span class="dropdown-menu-arrow"></span>
            <div class="pt-3 pl-5 pr-5 pb-3">
                <div class="row justify-content-end">
                    <div class="col-12 text-left">
                        <span class="invite-link-dropdown-title" translate>
                            {{ dropDownTitle }}
                        </span>
                    </div>
                </div>
                <div class="mt-2 d-flex align-items-center justify-content-between">
                    <div class="w-100">
                        <div class="form-group invite-link-group">
                            <div class="input-group mt-1" (click)="copyToClipboard(inviteLink)" matRipple>
                                <input aria-label="Invite link" type="text" class="form-control col invite-link-field"
                                    [value]="inviteLink || ''" disabled />
                                <div class="input-group-prepend copy">
                                    <div class="input-group-text">
                                        <i class="material-icons">content_copy</i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ml-2 mb-1">
                        <mat-progress-spinner *ngIf="loading; else newLink" class="custom-spinner"
                            mode="indeterminate" diameter="22">
                        </mat-progress-spinner>
                        <ng-template #newLink>
                            <i class="material-icons renew" (click)="requestLink()">autorenew</i>
                        </ng-template>
                    </div>
                </div>
                <div class="row justify-content-end">
                    <div class="col-12 text-left invite-link-suggestion mb-1">
                        <span [innerHTML]="getDropDownSuggestion()">
                        </span>
                    </div>
                    <div class="col-12 text-left">
                        <span translate>
                            {{ dropDownSubtitle }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
