<div class="row justify-content-center">
    <span class="circle-modal animate-grow-circle principal-color">
        <img src="../../../../assets/icons/send.svg" alt="send-icon"/>
    </span>
</div>
<i class="close material-icons-outlined" (click)="dialogClose()">close</i>
<div mat-dialog-content>
    <div class="invite-suggestion-container col-10">
        <div class="invite-suggestion-header">
            <p translate>optins.invite-link.email-suggestion-modal.title</p>
        </div>
        <div class="invite-suggestion-content" #emailTemplate>
            <p class="greetings" translate>optins.invite-link.email-suggestion-modal.text-1</p>
            <p translate>optins.invite-link.email-suggestion-modal.text-2</p>
            <p translate>optins.invite-link.email-suggestion-modal.text-3</p>
            <p translate>optins.invite-link.email-suggestion-modal.text-4</p>
            <p class="portal-link" (click)="openInviteLink()">{{ inviteLink }}</p>
            <p class="mt-4" translate>optins.invite-link.email-suggestion-modal.text-6</p>
            <p translate>optins.invite-link.email-suggestion-modal.text-7</p>
        </div>

        <div class="row justify-content-center align-items-center mt-6">
            <button class="btn btn-primary rounded text-uppercase mb-0 copy-email-template" (click)="copyToClipBoard()" translate>
                common.copy
            </button>
        </div>
    </div>
</div>
