import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    name: 'transformListToString'
})
export class TransformListToStringPipe implements PipeTransform {

    constructor(private translateService: TranslateService ) {}

    transform(items: any[]): string {
        let text = '';
        for (let i = 0; i < items.length; i++) {
            text += (this.translateService.instant(this.getTranslateKey(items[i])));
            if (items.length - i  !== 1) {
                text += ', ';
            }
        }
        return text;
    }

    getTranslateKey(hashAction: string) {
        const hashArray = hashAction.split('#');
        return `permissions.${hashArray[hashArray.length - 1]}`;
    }


}
