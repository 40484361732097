import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NoticePendentApprovalFoundComponent} from './notice-pendent-approval-found.component';
import {TranslateModule} from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MatDialogModule
    ],
    declarations: [NoticePendentApprovalFoundComponent],
    exports: [NoticePendentApprovalFoundComponent]
})
export class AdvisePendentApprovalFoundModule {
}
