/* src/app/shared/components/toggle-drop/toggle-drop.component.scss */
.dropdown-menu {
  margin-bottom: 25px;
  max-width: 30rem;
  padding: 5%;
  border-radius: 15px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1)) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.1));
}
.toggle-drop-anchor {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}
.toggle-drop-container {
  height: calc(1.5em + 0.75rem + 2px);
  display: flex;
}
.confirm-toggle {
  margin-top: 5%;
  margin-bottom: 1%;
  background: #0760ef !important;
}
.dropdown-menu:after {
  content: "";
  position: absolute;
  height: var(--h, 20px);
  width: var(--w, 30px);
  background: inherit;
  transform: scale(var(--x, 1), var(--y, 1));
  left: 89%;
  top: 99%;
  clip-path: polygon(0 0, 100% 0, 50% 100%);
}
.dropdown-toggle::after {
  display: none !important;
}
.align-middle {
  margin-top: 20px;
  margin-bottom: 20px;
}
/*# sourceMappingURL=toggle-drop.component-JRQXMQ7G.css.map */
