import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ITEMS_PER_PAGE} from '../../../app.constants';

@Component({
    selector: 'app-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

    @Input() totalItems = 0;
    @Input() currentPage = 0;

    @Output() paginationChange = new EventEmitter();

    constructor() {
    }

    ngOnInit(): void {
    }

    goToPage(newPage: number) {
        this.currentPage = newPage;
        this.paginationChange.emit(newPage);
    }

    getFakeArrayTotalPages() {
        return new Array(this.totalPages).fill(0);
    }

    get totalPages() {
        return Math.ceil(this.totalItems / ITEMS_PER_PAGE);
    }

}
