import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgOptionHighlightModule} from '@ng-select/ng-option-highlight';
import {CreateCompanyCanteenComponent} from './create-company-canteen.component';
import {ReactiveFormsModule} from '@angular/forms';
import {InputModule} from '../../../core/components/input/input.module';
import { ApprovePendingChangesModule } from '../approve-pending-changes/approve-pending-changes.module';
import { SelectModule } from '../../../core/components/select/select.module';
import { HistoricalDataModule } from '../historical-data/historical-data.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [
        CreateCompanyCanteenComponent
    ],
    imports: [
        TranslateModule,
        CommonModule,
        NgSelectModule,
        NgOptionHighlightModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        TranslateModule,
        ReactiveFormsModule,
        InputModule,
        ApprovePendingChangesModule,
        SelectModule,
        HistoricalDataModule
    ],
    exports: [
        CreateCompanyCanteenComponent
    ]
})
export class CreateCompanyCanteenModule {
}
