import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatSortModule } from '@angular/material/sort';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UiSwitchModule } from 'ngx-ui-switch';
import { ApprovePendingChangesModule } from '../../../shared/components/approve-pending-changes/approve-pending-changes.module';
import { EllipsifyModule } from '../../directives/ellipsify/ellipsify.module';
import { FinsteinTooltipModule } from '../../directives/finstein-tooltip/finstein-tooltip.module';
import { ConfirmDialogModule } from '../confirm-dialog/confirm-dialog.module';
import { PaginationModule } from '../pagination/pagination.module';
import { TableComponent } from './table.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import { TagInputModule } from 'ngx-chips';
import { NgCircleProgressModule } from 'ng-circle-progress';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        ConfirmDialogModule,
        MatDialogModule,
        FinsteinTooltipModule,
        UiSwitchModule,
        NgSelectModule,
        PaginationModule,
        NgOptionHighlightModule,
        NgCircleProgressModule,
        TagInputModule,
        EllipsifyModule,
        MatCheckboxModule,
        MatRippleModule,
        ApprovePendingChangesModule,
        MatSortModule,
        NgxSpinnerModule,
        MatTooltipModule,
    ],
    declarations: [TableComponent],
    providers: [],
    exports: [TableComponent],
})
export class TableModule {}
