import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
    selector: 'app-invite-email-suggestion-modal',
    templateUrl: './invite-email-suggestion-modal.component.html',
    styleUrls: ['./invite-email-suggestion-modal.component.scss'],
})
export class InviteEmailSuggestionModalComponent {
    isEmailTemplateCopied: boolean;
    inviteLink: string;

    @ViewChild('emailTemplate') emailTemplate: ElementRef;

    constructor(
        private dialogRef: MatDialogRef<InviteEmailSuggestionModalComponent>,
        private notification: ToastService,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.inviteLink = data.inviteLink;
    }

    openInviteLink() {
        window.open(this.inviteLink);
    }

    copyToClipBoard() {
        const element = this.emailTemplate.nativeElement;
        const range = document.createRange();
        range.selectNodeContents(element);
        const selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        document.execCommand('copy');
        selection.removeAllRanges();
        this.isEmailTemplateCopied = true;
        this.notification.info('optins.invite-link.email-copied-btn-label');
    }

    dialogClose() {
        this.dialogRef.close();
    }
}
