import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InviteLinkComponent } from './invite-link.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ClipboardModule, ClipboardService } from 'ngx-clipboard';
import { MatRippleModule } from '@angular/material/core';
import { InviteEmailSuggestionModalComponent } from './invite-email-suggestion-modal/invite-email-suggestion-modal.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [InviteLinkComponent, InviteEmailSuggestionModalComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    TranslateModule,
    NgbDropdownModule,
    MatRippleModule,
    ClipboardModule
  ],
  providers: [ClipboardService],
  exports: [InviteLinkComponent]
})
export class InviteLinkModule { }
