/* src/app/shared/components/list-company-units/list-company-units.component.scss */
.header-table th {
  font-weight: 500;
  text-transform: uppercase;
  color: #5B6D80;
  max-width: 150px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 1rem 15px;
}
.table td {
  padding: 0.2rem 15px 0.2rem 15px;
}
tr:nth-child(even) {
  background-color: #f0f7ff;
}
td,
th {
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  color: #8C969F;
}
td {
  max-width: 25vw;
  height: 45px;
}
.table ::ng-deep .ng2-tag-input,
.table ::ng-deep .ng2-tag-input__text-input {
  border: none;
  background: transparent;
}
.table ::ng-deep .ng2-tag-input__text-input {
  width: 7.3vw;
}
.table ::ng-deep .ng2-tag-input.ng2-tag-input--focused {
  border: none;
}
.table ::ng-deep .tag__text {
  color: #8C969F;
}
.table ::ng-deep delete-icon path {
  fill: #8C969F !important;
}
.table ::ng-deep .tag-wrapper {
  margin-top: -2px;
}
.table ::ng-deep tag:not(.readonly):not(.tag--editing):active {
  background: #e2e2e2;
}
.table ::ng-deep tag {
  border-radius: 4px;
}
.table-select ::ng-deep .ng-select-container {
  height: 40px;
}
.btn-action {
  margin-right: 5px;
}
.btn-action:last-child {
  margin-right: 0;
}
.approve-pendent-changes-icon {
  display: flex;
}
.approve-pendent-changes-icon ::ng-deep .icon-warning {
  position: relative !important;
}
.approve-pendent-changes-icon ::ng-deep .icon-warning i {
  margin-top: 12px !important;
}
.historical-icon {
  position: absolute;
  top: 9px;
  right: 13px;
}
/*# sourceMappingURL=list-company-units.component-UWSAQS4S.css.map */
