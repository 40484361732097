import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CreateCompanyCanteenComponent } from '../create-company-canteen-modal/create-company-canteen.component';
import { BaseComponent } from '../../../core/abstractions/base.component';
import { ConfirmDialogComponent } from '../../../core/components/confirm-dialog/confirm-dialog.component';
import { PERM_EDIT_COMPANY_MASTER_DATA } from '../../../app.constants';
import { MatDialog } from '@angular/material/dialog';

@Component({
    selector: 'app-list-company-canteens',
    templateUrl: './list-company-canteens.component.html',
    styleUrls: ['./list-company-canteens.component.scss']
})
export class ListCompanyCanteensComponent extends BaseComponent implements OnInit {

    @Input() items: any;
    @Input() isViewMode = false;
    @Input() actionsDisabled = false;

    @Output() itemsSubsidizedEvent = new EventEmitter();
    @Output() hasHistoricalView = new EventEmitter<any>();
    @Output() save = new EventEmitter();
    @Output() changeStatus = new EventEmitter();

    columnNames = ['zipCode', 'street', 'addressNumber', 'city', 'country', 'state', 'mailBoxNumber'];

    constructor(private dialog: MatDialog) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    getCollectionURL(): string {
        return '';
    }

    getRouterURL(): string {
        return '';
    }

    edit(index) {
        this.dialog.open(CreateCompanyCanteenComponent, {
            width: '45vw',
            panelClass: 'curved-modal',
            data: {
                entity: this.items[index],
                isViewMode: this.isViewMode
            }
        }).beforeClosed().subscribe(item => {
            if (item) {
                this.items[index] = item;
                this.itemsSubsidizedEvent.emit(this.items);
                this.save.emit(item);
            }
        });
    }

    remove(item) {
        this.dialog.open(ConfirmDialogComponent, {
            panelClass: 'curved-modal',
            data: {
                text: 'companies.units.confirmation-remove-subsidized'
            }
        }).afterClosed().subscribe((confirmed) => {
            if (confirmed) {
                this.changeStatus.emit(item);
            }
        });
    }

    get EDIT_COMPANY_MASTER_DATA() {
        return PERM_EDIT_COMPANY_MASTER_DATA;
    }

    hasPendentApproval(item) {
        return item && item.pendentApproval;
    }

    onApproveDetails(index) {
        this.edit(index);
    }

    hasHistoricalData(item: any) {
        return item && item.historicalData;
    }

}
