import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Functions, httpsCallableData } from '@angular/fire/functions';
import { Observable, of } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { FunctionOptions } from 'src/app/core/domain/function-options';
import { UserService } from 'src/app/core/services/user.service';
import {
    ADDRESSES_COLLECTION,
    COMPANIES_COLLECTION,
    COMPANY_TAX_INFORMATION,
    CONFIRM_CONTRACT_INSERTED_INTO_PAYROLL_SYSTEM_FUNCTION,
    OP_EQUALS,
    OP_IN,
    QUESTIONS_COLLECTION,
    STATUS_ACTIVE,
    USERS_COLLECTION,
    WAGE_ACCOUNTING_SYSTEM_COLLECTION
} from '../../../app.constants';
import { BaseService } from '../../../core/abstractions/base-service';
import { FireLoggingService } from '../../../core/services/fire-logging.service';
import { AddressesTypeEnum } from '../../../shared/enums/addresses-type.enum';
import { CompanyModel, QuestionsModel } from '../../../shared/models/company.model';

@Injectable({
    providedIn: 'root'
})
export class CompaniesService extends BaseService<CompanyModel> {
    public companyDisableData = {
        taxDeductible: {
            association: 'taxDeductibleRate',
            oldAssociationValue: false,
            enableAssociationValue: true
        },
        hasBav: {
            association: 'hasCalcBavPunctuality',
            oldAssociationValue: false,
            enableAssociationValue: true,
        },
        hasCalcBavPunctuality: {
            association: 'companyObligatoryContributionBav',
            oldAssociationValue: false,
            enableAssociationValue: false
        },
        considerBufferToTheMinWhage: {
            association: 'companyBuffer',
            oldAssociationValue: false,
            enableAssociationValue: true
        }
    };

    public questionsKeys = [
        'obligedPayLevy1',
        'obligedPayLevy2',
        'obligedPayLevy3',
        'taxDeductibleRate',
        'taxDeductible',
        'hasBav',
        'hasCalcBavPunctuality',
        'companyObligatoryContributionBav',
        'companySubsidyBav',
        'considerBufferToTheMinWhage'
    ];

    public addressKeys = [
        'street',
        'city',
        'zipCode',
        'state',
        'addressNumber',
        'country',
    ];

    constructor(private userService: UserService,
                protected firestore: AngularFirestore,
                protected http: HttpClient,
                protected functions: Functions,
                protected fireLogging: FireLoggingService) {
        super(firestore, http, functions, fireLogging);
    }

    public getCompany(): Observable<any> {
        return this.firestore.collection(COMPANIES_COLLECTION)
            .doc(this.userService.companyId)
            .get()
            .pipe(
                map(doc => {
                    const obj: any = doc.data();
                    obj.id = doc.id;
                    return obj;
                }),
                take(1),
                catchError((error) => {
                    const message = error.error ? error.error.message : error.message;
                    const path = `${ COMPANIES_COLLECTION }/${ this.userService.companyId }`;
                    this.fireLogging.sendErrorLog(`[getCompany(companies.service.ts)] An error occurred while fetching item ${ path }, details: ${ message || JSON.stringify(error) }`);
                    return of({error});
                })
            );
    }

    public getMainAddressByCompany(id: string, path: string, functionsOpts?: FunctionOptions): Observable<any> {
        if (functionsOpts) {
            return super.callFunction(null, path, functionsOpts);
        } else {
            return this.firestore.collection(path).doc(id)
                .collection(ADDRESSES_COLLECTION, ref => ref.where('main', OP_EQUALS, true))
                .snapshotChanges()
                .pipe(
                    map(actions => actions.map((action) => {
                        const data: any = action.payload.doc.data();
                        data.id = action.payload.doc.id;
                        return data;
                    })),
                    catchError((error) => {
                        const message = error.error ? error.error.message : error.message;
                        this.fireLogging.sendErrorLog(`[getMainAddressByCompany(companies.service.ts)] An error occurred while fetching item ${ ADDRESSES_COLLECTION } with filter: {main == true}, details: ${ message || JSON.stringify(error) }`);
                        return of({error});
                    })
                );
        }
    }

    public getCompanyAddressById(companyId: string, addressId: string,): Observable<any> {
        return this.firestore.collection(COMPANIES_COLLECTION)
        .doc(companyId)
        .collection(ADDRESSES_COLLECTION)
        .doc(addressId)
        .get()
        .pipe(
            map(res => {
                const data: any = res.data();
                data.id = res.id;
                return data;
            }),
            catchError((error) => {
                const message = error.error ? error.error.message : error.message;
                this.fireLogging.sendErrorLog(`[getCompanyAddressById(companies.service.ts)] An error occurred while fetching item ${ ADDRESSES_COLLECTION }/${companyId}/${addressId} details: ${ message || JSON.stringify(error) }`);
                throw { error };
            })
        );
    }

    public getCompanyResponsible(companyId: string): Observable<any> {
        return this.firestore.collection(USERS_COLLECTION, ref =>
            ref.where('companyId', OP_EQUALS, companyId).where('companyResponsible', OP_EQUALS, true).limit(1))
            .snapshotChanges()
            .pipe(
                map(
                    actions => actions.map((action) => {
                        const data: any = action.payload.doc.data();
                        data.id = action.payload.doc.id;
                        return data;
                    })
                ),
                map(docs => docs[0]),
                catchError((error) => {
                    const message = error.error ? error.error.message : error.message;
                    this.fireLogging.sendErrorLog(`[getCompanyResponsible(companies.service.ts)] An error occurred while fetching item ${ USERS_COLLECTION } with filter: {companyResponsible == true}, details: ${ message || JSON.stringify(error) }`);
                    return of({error});
                })
            );
    }

    public isAssociation(companyDisableData: any, fieldName: string) {
        return Object.keys(companyDisableData).find((key) => {
            return companyDisableData[key].association === fieldName;
        });
    }

    public getQuestionsByCompany(id: string, openStream = false): Observable<QuestionsModel> {
        return this.getSubCollectionByCompanyId(id, QUESTIONS_COLLECTION, openStream);
    }

    public getAllCanteens(status: string | string[] = STATUS_ACTIVE): Observable<any> {
        const statusList = Array.isArray(status) ? status : [ status ];
        const companyId = this.userService.loggedUser.companyId;
        return this.firestore
            .collection(COMPANIES_COLLECTION)
            .doc(companyId)
            .collection(ADDRESSES_COLLECTION, (ref) => {
                return ref.where('status', OP_IN, statusList).where('type', '==', 'CANTEEN');
            })
            .snapshotChanges()
            .pipe(
                map(actions => actions.map((action) => {
                    const data: any = action.payload.doc.data();
                    data.id = action.payload.doc.id;
                    return data;
                })),
                catchError((error) => {
                    const message = error.error ? error.error.message : error.message;
                    const path = `${ COMPANIES_COLLECTION }/${ companyId }/${ ADDRESSES_COLLECTION }`;
                    this.fireLogging.sendErrorLog(`[getAllCanteens(companies.service.ts)] An error occurred while fetching item ${ path }, details: ${ message || JSON.stringify(error) }`);
                    return of({error});
                })
            );
    }

    public getAllCompanyAddresses(): Observable<any> {
        const companyId = this.userService.loggedUser.companyId;
        return this.firestore
            .collection(COMPANIES_COLLECTION)
            .doc(companyId)
            .collection(ADDRESSES_COLLECTION, ref => {
                return ref.where('status', '==', STATUS_ACTIVE).where('type', '==', AddressesTypeEnum.WORK_ADDRESS);
            })
            .snapshotChanges()
            .pipe(
                map(actions => actions.map((action) => {
                    const data: any = action.payload.doc.data();
                    data.id = action.payload.doc.id;
                    return data;
                })),
                catchError((error) => {
                    const message = error.error ? error.error.message : error.message;
                    const path = `${ COMPANIES_COLLECTION }/${ companyId }/${ ADDRESSES_COLLECTION }`;
                    this.fireLogging.sendErrorLog(`[getAllCompanyAddresses(companies.service.ts)] An error occurred while fetching item ${ path }, details: ${ message || JSON.stringify(error) }`);
                    return of({error});
                })
            );
    }

    public getCompanyUnits(status: string | string[] = STATUS_ACTIVE): Observable<any> {
        const statusList = Array.isArray(status) ? status : [ status ];
        const companyId = this.userService.loggedUser.companyId;
        return this.firestore
            .collection(COMPANIES_COLLECTION)
            .doc(companyId)
            .collection(ADDRESSES_COLLECTION, ref =>
                ref.where('status', OP_IN, statusList)
                    .where('main', '==', false)
                    .where('type', '==', AddressesTypeEnum.WORK_ADDRESS)
            )
            .snapshotChanges()
            .pipe(
                map(actions => actions.map((action) => {
                    const data: any = action.payload.doc.data();
                    data.id = action.payload.doc.id;
                    return data;
                })),
                catchError((error) => {
                    const message = error.error ? error.error.message : error.message;
                    const path = `${ COMPANIES_COLLECTION }/${ companyId }/${ ADDRESSES_COLLECTION }`;
                    this.fireLogging.sendErrorLog(`[getCompanyUnits(companies.service.ts)] An error occurred while fetching item ${ path }, details: ${ message || JSON.stringify(error) }`);
                    return of({error});
                })
            );
    }


    public getUserPermissionsCompany(idCompany: string, path: string): Observable<CompanyModel | any> {
        return this.firestore
            .collection(path)
            .doc(idCompany)
            .get()
            .pipe(
                map((res) => res.data() as CompanyModel),
                catchError((error) => {
                    const message = error.error ? error.error.message : error.message;
                    this.fireLogging.sendErrorLog(`[getUserPermissionsCompany(companies.service.ts)] An error occurred while fetching item ${ path }/${ idCompany }, details: ${ message || JSON.stringify(error) }`);
                    return of({error});
                })
            );
    }

    private getSubCollectionByCompanyId<T>(companyId: string, subCollection: string, openStream: boolean) {
        if (openStream) {
            return this.firestore.collection(COMPANIES_COLLECTION)
                .doc(companyId)
                .collection(subCollection)
                .doc(COMPANY_TAX_INFORMATION)
                .snapshotChanges()
                .pipe(
                    map(action => {
                        const data: any = action.payload.data();
                        data.id = action.payload.id;
                        return data;
                    }),
                    catchError((error) => {
                        const message = error.error ? error.error.message : error.message;
                        const path = `${COMPANIES_COLLECTION}/${companyId}/${subCollection}/${COMPANY_TAX_INFORMATION}`;
                        this.fireLogging.sendErrorLog(`[getSubCollectionByCompanyId(companies.service.ts)] An error occurred while fetching item ${ path }, details: ${ message || JSON.stringify(error) }`);
                        return of({error});
                    })
                );
        } else {
            return this.firestore.collection(COMPANIES_COLLECTION).doc(companyId)
                .collection(subCollection).doc(COMPANY_TAX_INFORMATION).get()
                .pipe(
                    map(res => ({id: res.id, ...res.data()})),
                );
        }
    }

    public hasAnyPermission(value: string | string[]) {
        const result = [];
        if (typeof value === 'string') {
            this.processPermission(value, result);
        } else {
            value.forEach((permission) => {
                this.processPermission(permission, result);
            });
        }
        return this.userService.loggedUser.permissions.some((authority: string) =>
            result.includes(authority)
        );
    }

    public getCurrentWageAccountingSystem(): Observable<any> {
        return this.firestore
            .collection(`${ this.getPayrollCollectionURL() }/${ WAGE_ACCOUNTING_SYSTEM_COLLECTION }`,
                ref => ref.where('status', OP_EQUALS, STATUS_ACTIVE))
            .get()
            .pipe(
                map(res => {
                    if (res.empty) {
                        return null;
                    } else {
                        const data: any = res.docs[0].data();
                        data.id = res.docs[0].id;
                        return data;
                    }
                }),
                take(1), // Only once,
                catchError((error) => {
                    const message = error.error ? error.error.message : error.message;
                    const path = `${ this.getPayrollCollectionURL() }/${ WAGE_ACCOUNTING_SYSTEM_COLLECTION }`;
                    this.fireLogging.sendErrorLog(`[getCurrentWageAccountingSystem(companies.service.ts)] An error occurred while fetching item ${ path }, details: ${ message || JSON.stringify(error) }`);
                    return of({error});
                })
            );
    }

    public submitConfirmContractInsertedIntoPayrollSystem(data: any) {
        return httpsCallableData(this.functions, CONFIRM_CONTRACT_INSERTED_INTO_PAYROLL_SYSTEM_FUNCTION)(data);
    }

    processPermission(value: string, arrayResult: string[]) {
        if (value.split('#').length === 1) {
            arrayResult.push(value);
            arrayResult.push(`process#holder#${ value }`);
            arrayResult.push(`process#standIn#${ value }`);
        } else {
            arrayResult.push(value);
        }
    }

    private getPayrollCollectionURL(): string {
        return `${ COMPANIES_COLLECTION }/${this.userService.loggedUser.companyId }/${ QUESTIONS_COLLECTION }/payroll`;
    }

}

