<div class="position-relative" style="min-height: 400px;">
    <ngx-spinner
        [zIndex]="1"
        name="table-spinner"
        bdColor="rgba(0,0,0,0.02)"
        color="#275FA1"
        size="medium"
        type="ball-clip-rotate"
        [fullScreen]="false">
    </ngx-spinner>

    <ng-container *ngIf="items && items.length > 0; then table; else empty"></ng-container>

    <ng-template #empty>
        <div *ngIf="items !== undefined" class="jumbotron jumbotron-fluid pl-4 fade-in">
            <div class="container d-flex align-items-center flex-column">
                <h1 class="display-4 d-flex align-items-center text-nowrap" translate>common.no-search-elements
                    <i class="material-icons search-off">search_off</i>
                </h1>
            </div>
        </div>
    </ng-template>

    <ng-template #table>
        <div class="app-table row no-margin fade-in">
            <table matSort class="table dataTable table-vmiddle"
                   [ngClass]="{'table-borderless': !bordered, 'table-bordered': bordered}"
                   [ngStyle]="style?.table"
                   (matSortChange)="onChangeSort($event)">
                <thead class="header-table">
                <tr>
                    <!-- HEAD FOR SELECT BUTTON (OPTIONAL) -->
                    <th class="th-checkbox" *ngIf="hasRowSelection() && items && items.length > 0" scope="col"
                        [ngStyle]="style?.th">
                        <mat-checkbox class="mr-4" *ngIf="checkAllOption" (change)="selectAll()"
                                      [checked]="isAllChecked()">
                        </mat-checkbox>
                    </th>

                    <th *ngIf="hasAnyError()" style="width: 0;"></th>

                    <!-- TABLE HEADS -->
                    <ng-container *ngFor="let column of columns; let index = index">
                        <th scope="col"
                            [ngStyle]="style?.th"
                            [id]="column.property"
                            [mat-sort-header]="column.property"
                            [disabled]="!column.sortable">
                            {{ column.label | translate }}
                        </th>
                    </ng-container>
                    <th *ngIf="hasAnyAction()" scope="col" [ngStyle]="style?.th"></th>

                </tr>
                </thead>

                <tbody>
                <ng-container *ngFor="let item of items; let index = index">
                    <tr *ngIf="verifyIsChecked? isChecked(item): true">
                        <!-- SELECT BUTTONS (OPTIONAL) -->
                        <td *ngIf="hasRowSelection() && items && items.length > 0">
                            <mat-checkbox class="mr-4" [id]="checkboxRandomId+'-'+index" (change)="onRowSelect(item)"
                                          [disabled]="isCheckDisabled(item)" [checked]="isChecked(item)">
                            </mat-checkbox>
                        </td>
                        <td *ngIf="hasAnyError()" style="width: 0;">
                            <i class="report-icon" *ngIf="hasAnyError(item)" [matTooltip]="item?.errorReport | translate" class="material-icons report-icon">
                                report
                            </i>
                        </td>
                        <!-- RENDER ONE OF THE VALUES -->
                        <td *ngFor="let column of columns; let indexColumn = index" [ngStyle]="getColumnStyle(column, item)">

                            <div *ngIf="column.property" (click)="onClickValue(item, column)"
                                 [ngClass]="getCssClass(column, item)" translate>

                                <!-- ICONS -->

                                <div class="d-flex justify-content-center align-items-center" *ngIf="column.icon">
                                    <img alt="Icon" class="icon-text" [ngClass]="{ 'd-none': !item.iconLoaded}"
                                        (load)="item.iconLoaded = true" [src]="getIconURL(item.icon)">
                                    <div *ngIf="!item.iconLoaded" class="spinner-grow text-primary">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>

                                <div class="d-flex justify-content-center align-items-center" *ngIf="column.materialIcon">
                                   <span class="material-icons-outlined font-icon" [ngStyle]="column.style">
                                        {{column.property}}
                                   </span>
                                </div>

                                <!-- TEXT -->

                                <div class="col-6 float-right" *ngIf="hasPendentApprovalForThisProperty(item, column)">
                                    <i finsteinTooltip="messages.property-with-pendent-approval"
                                    class="material-icons warning-icon mt-2">
                                        warning
                                    </i>
                                </div>

                                <span [ngClass]="{'ml-4': column.icon}"
                                      [ngStyle]="getColumnStyle(column, item)"
                                    *ngIf="!hasActiveColumn(column) && !column.input && !column.taggerInput && !column.select && !hasCustomStatusColumn(column)"
                                      [matTooltip]="getTooltip(item, column)"
                                    appEllipsify>
                                    {{getColumnValue(item, column)}}
                                </span>

                                <!-- OR -->

                                <!-- SELECT -->
                                <div *ngIf="!hasActiveColumn(column) && column.select">
                                    <div class="row" *ngIf="!inputStates[column.property][index]">
                                        <div class="col-12 text-center pl-6 pr-6">
                                            <span>{{getColumnValue(item, column)}}</span>
                                        </div>
                                        <div class="icon-edit-input"
                                            *ngIf="!hasPendentApprovalForThisProperty(item, column)"
                                            (click)="changeInputState(column, index, 'id-' + column.property + '-' + index)">
                                            <i class="material-icons icon">create</i>
                                        </div>
                                    </div>

                                    <ng-select [hidden]="!inputStates[column.property][index]"
                                            class="table-select"
                                            [id]="'id-' + column.property + '-' + index"
                                            [items]="column?.select?.items"
                                            [multiple]="column?.select?.multiple"
                                            [closeOnSelect]="false"
                                            [searchable]="true"
                                            [markFirst]="false"
                                            (close)="disableInput(column, index)"
                                            (blur)="disableInput(column, index)"
                                            (change)="column?.select?.onChange($event)"
                                            [bindLabel]="column?.select?.bindLabel">

                                        <!-- USER SELECTED -->
                                        <ng-template ng-label-tmp let-item="item">
                                            <span>{{item[column?.select?.showProperty]}}</span>
                                        </ng-template>

                                        <!-- LIST OPTIONS -->
                                        <ng-template ng-option-tmp let-item="item" let-index="index"
                                                    let-search="searchTerm">
                                            <span [ngOptionHighlight]="search" [title]="item[column?.select?.showProperty]">
                                                {{item[column?.select?.showProperty]}}
                                            </span>
                                        </ng-template>
                                    </ng-select>
                                </div>

                                <!-- OR -->

                                <!-- INPUT -->
                                <div *ngIf="!hasActiveColumn(column) && column.input">
                                    <div class="row" *ngIf="!inputStates[column.property][index]">
                                        <div class="col-12 text-center pl-6 pr-6">
                                            <span>{{getColumnValue(item, column)}}</span>
                                        </div>
                                        <div class="icon-edit-input"
                                            *ngIf="!hasPendentApprovalForThisProperty(item, column)"
                                            (click)="changeInputState(column, index, 'id-' + column.property + '-' + index)">
                                            <i class="material-icons icon">create</i>
                                        </div>
                                    </div>
                                    <input class="form-control col text-center"
                                        maxlength="150"
                                        aria-label="Consultant Code"
                                        [hidden]="!inputStates[column.property][index]"
                                        [id]="'id-' + column.property + '-' + index"
                                        [(ngModel)]="item[column.property]"
                                        (keyup.enter)="disableInput(column, index)"
                                        (change)="column?.input?.onChange(item)"
                                        (blur)="disableInput(column, index)"/>
                                </div>

                                <!-- OR -->

                                <!-- TAGGER INPUT -->
                                <div *ngIf="!hasActiveColumn(column) && !!column.taggerInput">
                                    <div class="row" *ngIf="!inputStates[column.property][index]">
                                        <div class="col-12 text-center pl-6 pr-6">
                                            <span>{{getColumnValue(item, column)}}</span>
                                        </div>
                                        <div class="icon-edit-input"
                                            *ngIf="!hasPendentApprovalForThisProperty(item, column)"
                                            (click)="changeInputState(column, index)">
                                            <i class="material-icons icon">create</i>
                                        </div>
                                    </div>

                                    <div class="row" *ngIf="inputStates[column.property][index]">
                                        <div class="col-12 text-center pl-6 pr-7">
                                            <tag-input placeholder="{{column.taggerInput.placeholder | translate}}"
                                                    [clearOnBlur]="true"
                                                    [modelAsStrings]="true"
                                                    [animationDuration]="{enter: '50ms', leave: '0ms'}"
                                                    [(ngModel)]="item[column.property]"
                                                    (onAdd)="column?.taggerInput?.onChange(item)"
                                                    (onRemove)="column?.taggerInput?.onChange(item)">
                                            </tag-input>
                                        </div>
                                        <div class="icon-edit-input" (click)="changeInputState(column, index)">
                                            <i class="material-icons icon">done</i>
                                        </div>
                                    </div>
                                </div>

                                <!-- OR -->

                                <!-- SWITCH BUTTON -->
                                <div *ngIf="hasSwitchAction() && hasActiveColumn(column)" class="mb-3">
                                    <ui-switch [disabled]="viewMode" (change)="onSwitch(item)" [checked]="isChecked(item)"></ui-switch>
                                </div>

                                <!-- CUSTOM STATUS COLUMN -->
                                <div *ngIf="hasCustomStatusColumn(column)" class="d-flex align-items-center justify-content-center">
                                    <i class="material-icons-outlined col-4 text-right pr-0 pl-0" [ngClass]="getCustomStatusColumn(item, column).iconClass">
                                        {{getCustomStatusColumn(item, column).icon}}
                                    </i>
                                    <span class="col text-left" translate>{{getCustomStatusColumn(item, column).label}}</span>
                                    <!-- <div class="d-flex align-items-center">
                                    </div> -->
                                </div>
                            </div>

                            <!-- CIRCLE PROGRESS BAR -->
                            <div *ngIf="!column.property && column.progressPercentage" class="cursor-default"
                                [finsteinTooltip]="column.progressTooltip(item)" translate>
                                <circle-progress [percent]="column.progressPercentage(item)"></circle-progress>
                            </div>

                        </td>
                        <!-- ACTION BUTTONS -->
                        <td *ngIf="hasAnyAction()" class="actions justify-content-center">
                            <app-approve-pending-changes *ngIf="hasPendentApproval(item); else actionButtons"
                                                        [item]="item"
                                                        (details)="onApprovalDetails(item)"
                                                        [permissionToApprove]="permissionToApprove">
                            </app-approve-pending-changes>
                            <ng-template #actionButtons>
                                <button *ngFor="let button of customActions; trackBy: trackButton; let i = index"
                                        class="btn-action"
                                        [disabled]="isCustomActionDisabled(button, item) || viewMode"
                                        [ngStyle]="getCustomStyle(button, item)"
                                        (click)="button.onClick(item)"
                                        [finsteinTooltip]="button.tooltip"
                                        matRipple>
                                    <i class="material-icons">{{button.icon}}</i>
                                </button>
                                <button *ngIf="hasEditAction() && hasAuthorityToEdit()"
                                        id="edit"
                                        class="btn-action"
                                        (click)="onEdit(item.id)"
                                        [disabled]="item.default
                                            || item.status === STATUS_PENDENT
                                            || viewMode
                                            || isStatusDisabledItem(disableEditBtnByStatus, item)"
                                        finsteinTooltip="tooltips.edit"
                                        matRipple>
                                    <i class="material-icons">create</i>
                                </button>
                                <button *ngIf="hasViewAction() && hasAuthorityToView()"
                                        id="view"
                                        class="btn-action"
                                        (click)="onView(item)"
                                        finsteinTooltip="tooltips.view"
                                        [disabled]="isStatusDisabledItem(disableViewBtnByStatus, item)"
                                        matRipple>
                                    <i class="material-icons">list</i>
                                </button>
                                <button *ngIf="hasRemoveAction() && hasAuthorityToRemove()"
                                        id="remove"
                                        class="btn-action"
                                        data-toggle="modal"
                                        data-target="#modal-confirm-deletion"
                                        (click)="itemToChangeStatus(item)"
                                        [disabled]="item.default
                                            || item.status === STATUS_PENDENT
                                            || hasPendentApprovalForCreateAction(item)
                                            || viewMode
                                            || isStatusDisabledItem(disableDeleteBtnByStatus, item)"
                                        finsteinTooltip="tooltips.delete"
                                        matRipple>
                                    <i class="material-icons">delete</i>
                                </button>
                            </ng-template>

                            <!-- DROPDOWN ACTION BUTTONS -->

                            <div class="btn-group" role="group" *ngIf="hasAnyDropdownAction()">
                                <button type="button" class="btn-action" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="material-icons">more_vert</i>
                                </button>
                                <ul class="dropdown-menu">
                                    <div *ngFor="let button of dropdownCustomActions; trackBy: trackButton; let i = index">
                                        <li
                                            *ngIf="!isCustomActionDisabled(button, item)"
                                            class="dropdown-item"
                                            (click)="button.onClick(item)"
                                            translate> {{ button.title }}</li>
                                    </div>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </div>
    </ng-template>
</div>
<div class="row justify-content-center table-pagination mt-5">
    <app-pagination [totalItems]="totalItems" [currentPage]="currentPage"
                    (paginationChange)="onPaginationChanged($event)">
    </app-pagination>
</div>
