<div class="cookie-banner">
    <div class="container no-padding">
        <div class="row">
            <div class="col-lg-9 col-md-12 d-flex align-items-center">
                <small class="m-auto" translate>cookies.text</small>
            </div>
            <div class="col-lg-3 col-md-12 d-flex align-items-center">
                <button class="btn btn-primary float-right got-it-button"
                        translate>
                    cookies.button
                </button>
            </div>
        </div>
    </div>
</div>
