/* src/app/shared/components/create-user-modal/create-user-modal.component.scss */
.page-title {
  font-size: 1rem;
  text-transform: uppercase;
  color: #6c757d;
  width: 100%;
}
.btn-primary {
  background: #0760ef !important;
  border: 1px #0760ef !important;
}
.btn-outline-primary {
  border-radius: 40px;
  float: right;
  margin-bottom: 30px;
  padding: 8px 17px;
  min-width: 115px !important;
  border-color: #0760ef !important;
}
.btn-outline-primary:hover {
  background: #0760ef !important;
}
/*# sourceMappingURL=create-user-modal.component-XMWPF4SB.css.map */
