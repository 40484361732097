/* src/app/shared/components/input-file/input-file.component.scss */
.upload-input {
  display: flex;
  align-content: center;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.0901960784);
  border-radius: 5px;
  padding: 2%;
  cursor: pointer;
  align-items: center;
}
.upload-input .icon-svg svg {
  width: 20px;
  height: 20px;
}
.invalid {
  border-color: #dc3545;
}
.upload-input-content-disabled {
  background-color: transparent;
  color: #6c757d;
  opacity: 0.5;
  pointer-events: none;
}
/*# sourceMappingURL=input-file.component-BPY6NBR5.css.map */
