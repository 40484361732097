/* src/app/shared/components/invite-link/invite-link.component.scss */
.invite-link-dropdown-container {
  position: absolute;
  right: 0;
  margin-top: 2px;
}
.invite-link-dropdown-menu {
  width: 30vw;
  margin-top: -3px;
}
.invite-link-group input:disabled {
  background-color: #f6f6f6;
}
.invite-link-dropdown-title {
  color: #275fa1;
  font-weight: 600;
  font-size: 1rem;
}
.renew {
  color: #5b6c80;
  cursor: pointer;
}
.copy .input-group-text {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}
.copy .input-group-text i {
  cursor: pointer;
  color: #5b6c80;
}
.copy .input-group-text:hover {
  background-color: #f3f3f3;
}
.dropdown-menu-arrow {
  top: -17px;
  left: 95%;
  height: 0;
  position: relative;
}
.dropdown-menu-arrow:before,
.dropdown-menu-arrow:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-width: 9px 8px;
  border-style: solid;
  border-color: transparent;
  z-index: 1001;
  top: -9px;
}
.dropdown-menu-arrow:after {
  bottom: -18px;
  right: -10px;
  border-bottom-color: #cecbcb;
}
.dropdown-menu-arrow:before {
  bottom: -18px;
  right: -10px;
  border-bottom-color: rgba(0, 0, 0, 0.15);
}
.rounded {
  border-radius: 20px !important;
}
.nav-tabs {
  border-color: transparent !important;
  background: #0760ef !important;
}
.nav-tabs .nav-link {
  color: white;
}
.nav-link.active {
  background: white;
  color: #0760ef !important;
}
.invite-link-suggestion span a {
  text-decoration: underline;
}
/*# sourceMappingURL=invite-link.component-HDNCSC4A.css.map */
