import { Pipe, PipeTransform } from '@angular/core';
import {STATUS_ACTIVE} from '../../app.constants';

@Pipe({
  name: 'filterActiveItems'
})
export class FilterActiveItemsPipe implements PipeTransform {

  transform(items: any[]): any {
      if (!items) {
          return items;
      }
      return items.filter(item => item.status === STATUS_ACTIVE);
  }
}
