import { HistoricalDataModule } from '../historical-data/historical-data.module';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {TranslateModule} from '@ngx-translate/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgOptionHighlightModule} from '@ng-select/ng-option-highlight';
import {ReactiveFormsModule} from '@angular/forms';
import {InputModule} from '../../../core/components/input/input.module';
import {ListCompanyUnitsComponent} from './list-company-units.component';
import {EllipsifyModule} from '../../../core/directives/ellipsify/ellipsify.module';
import { ApprovePendingChangesModule } from '../approve-pending-changes/approve-pending-changes.module';
import { FinsteinTooltipModule } from 'src/app/core/directives/finstein-tooltip/finstein-tooltip.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
    declarations: [
        ListCompanyUnitsComponent
    ],
    imports: [
        TranslateModule,
        CommonModule,
        NgSelectModule,
        NgOptionHighlightModule,
        MatDialogModule,
        MatProgressSpinnerModule,
        TranslateModule,
        ReactiveFormsModule,
        InputModule,
        EllipsifyModule,
        ApprovePendingChangesModule,
        FinsteinTooltipModule,
        HistoricalDataModule
    ],
    exports: [
        ListCompanyUnitsComponent
    ]
})
export class ListCompanyUnitsModule {
}
